import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "../../app/stores/store";
import { ChartGrid } from "./PieChartGrid";
import * as yup from "yup";
import { DetailedPieChart } from "./DetailedPieChart";
import BarChartGrid from "./BarChartGrid";
import ProjectStatusCard from "./components/ProjectStatusCard";
import LoadingSection from "../approval/components/LoadingSection";
import DynamicBarDialog from "./DynamicBarDialog";
import CSelect from "../../app/component/CSelect";
import { ChevronRight, Close, ExpandMore } from "@mui/icons-material";
import { TreeItem, TreeView } from "@mui/lab";
import { OrgList } from "./ProjectDataGrid";
import { ProjectCategory } from "../../app/config/enum";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { useFormik } from "formik";

function Home() {
  const {
    accountStore: { getUser },
  } = useStore();
  const { setDataPointIndexBarChart } = useStore().DashboardStore;
  const { getOrganizationList } = useStore().organizationStore;
  const [organizationsList, setOrganizationsList] = useState<OrgList[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<OrgList>();

  const {
    dashboardPieCategory,
    dashboardPieOverdue,
    dashboardProjectStatus,
    dashboardLineProjectCount,
    dashboardBarRealization,
    dashboardPieCategoryLoading,
    dashboardPieOverdueLoading,
    dashboardProjectStatusLoading,
    dashboardLineProjectCountLoading,
    dashboardBarRealizationLoading,
    dashboardBarProjectSummary,
    dashboardBarRealizationPlanDetail,
    dataPointIndexBarChart,
    getDashboardPieCategory,
    getDashboardPieOverdue,
    getDashboardProjectStatus,
    getDashboardPieStatus,
    getDashboardLineProjectCost,
    getDashboardLineProjectCount,
    getDashboardBarRealization,
    getDashboardBarRealizationPlanDetail,
    getDashboardBarProjectSummary,
  } = useStore().DashboardStore;

  const projectBudgetSeries = ["Plan", "Realization"];

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      year: new Date().getFullYear().toString(),
      organizationId: "",
      projectCategory: "",
    },
    validationSchema: {
      year: yup.string(),
      organizationId: yup.string(),
      projectCategory: yup.string(),
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const renderTreeItem = (nodes: any) => {
    return (
      <TreeItem onClick={() => handleChangeOrganizationId(nodes)} key={nodes.id} nodeId={nodes.id} label={nodes.name}>
        {Array.isArray(nodes.child) ? nodes.child.map((node: any) => renderTreeItem(node)) : null}
      </TreeItem>
    );
  };

  const isLoading = useMemo(() => {
    if (dashboardPieCategoryLoading) return true;
    if (dashboardProjectStatusLoading) return true;
    if (dashboardPieOverdueLoading) return true;
    if (dashboardBarRealizationLoading) return true;
    if (dashboardLineProjectCountLoading) return true;

    return false;
  }, [
    dashboardPieCategoryLoading,
    dashboardProjectStatusLoading,
    dashboardPieOverdueLoading,
    dashboardBarRealizationLoading,
    dashboardLineProjectCountLoading,
  ]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    getDashboardPieCategory();
    getDashboardPieOverdue();
    getDashboardLineProjectCost();
    getDashboardLineProjectCount();
    getDashboardProjectStatus();
    getDashboardBarRealization();
    getDashboardBarProjectSummary();
  }, [
    getDashboardPieCategory,
    getDashboardPieStatus,
    getDashboardPieOverdue,
    getDashboardLineProjectCount,
    getDashboardLineProjectCost,
    getDashboardBarRealization,
    getDashboardProjectStatus,
    getDashboardBarProjectSummary,
  ]);

  useEffect(() => {
    if (!dataPointIndexBarChart) return;

    if (projectBudgetSeries.includes(dataPointIndexBarChart)) {
      getDashboardBarRealizationPlanDetail(dataPointIndexBarChart.toLowerCase());
    } else {
      getDashboardBarProjectSummary();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPointIndexBarChart, getDashboardBarProjectSummary, getDashboardBarRealizationPlanDetail]);

  useEffect(() => {
    getOrganizationList().then((organizationRes) => {
      setOrganizationsList(organizationRes?.map((item) => ({ ...item, text: item.name, value: item.id } as any)) ?? []);
    });
  }, [getOrganizationList]);

  const handleChangeOrganizationId = (node: any) => {
    setSelectedOrg(node);
    setFieldValue("organizationId", node.id);
  };

  useEffect(() => {
    let params = {};

    const keys = Object.keys(values);
    for (let key of keys) {
      const value = (values as any)[key];

      if (!!value)
        params = {
          ...params,
          [key]: value,
        };
    }

    getDashboardProjectStatus(params);
    getDashboardPieCategory(params);
    getDashboardPieOverdue(params);
    getDashboardLineProjectCount(params);
    getDashboardBarRealization(params);
    getDashboardBarProjectSummary(params);
  }, [
    values,
    getDashboardProjectStatus,
    getDashboardPieCategory,
    getDashboardPieOverdue,
    getDashboardLineProjectCount,
    getDashboardBarRealization,
    getDashboardBarProjectSummary,
  ]);

  useEffect(() => {
    setDataPointIndexBarChart("");
  }, []);

  return (
    <Stack gap={3}>
      <Typography sx={{ textAlign: "center", my: "12px" }} variant="h1" color="black">
        Executive Dashboard
      </Typography>
      <Grid
        container
        spacing={1}
        sx={{
          alignItems: "center",
          mt: "2px",
          backgroundColor: "white",
          p: "12px 12px 24px 12px",
          borderRadius: "10px",
          boxShadow: "0 20px 25px -5px rgba(0,0,0,0.1), 0 8px 10px -6px rgba(0,0,0,0.1)",
        }}
      >
        <Grid item xs={12} sx={{ mb: "12px" }}>
          <Typography variant="h2" color="black">
            Filter Box
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <CSelect
            value={values.projectCategory}
            onChange={handleChange}
            label="Project Category"
            name="projectCategory"
            size="small"
            onClear={() => setFieldValue("projectCategory", "")}
            options={[
              { label: "Business Development", value: ProjectCategory.BD },
              {
                label: "Non-Business Development",
                value: ProjectCategory.NonBD,
              },
              { label: "Anorganik", value: ProjectCategory.Anorganik },
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel size="small" id="organization-select-id">
              Organization
            </InputLabel>
            <Select
              labelId="organization-select-id"
              id="organization-select-id"
              label="Organization"
              value={values.organizationId}
              displayEmpty
              size="small"
              endAdornment={
                !!values.organizationId ? (
                  <Close
                    onClick={() => {
                      setSelectedOrg(undefined);
                      setFieldValue("organizationId", "");
                    }}
                    sx={{ mr: 2, cursor: "pointer" }}
                    fontSize="small"
                  />
                ) : undefined
              }
            >
              <MenuItem sx={{ display: "none" }} value={values.organizationId}>
                {selectedOrg?.name}
              </MenuItem>
              <TreeView
                aria-label="select organization"
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                sx={{ height: 240, flexGrow: 1, overflowY: "auto" }}
              >
                {!!organizationsList?.length &&
                  organizationsList.map((item) => {
                    return renderTreeItem(item);
                  })}
              </TreeView>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Year"
              componentsProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              value={values.year}
              views={["year"]}
              inputFormat="yyyy"
              onChange={(e: any) => setFieldValue("year", format(new Date(e), "yyyy") === "1970" ? null : format(new Date(e), "yyyy"))}
              renderInput={(props) => <TextField onFocus={(e) => e.target.blur()} disabled size="small" fullWidth name="startYear" {...props} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      {isLoading ? (
        <LoadingSection />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              {dashboardLineProjectCount && (
                <Paper
                  sx={{
                    borderRadius: "10px",
                    p: "12px 20px 20px 20px",
                    boxShadow: "0 20px 25px -5px rgba(0,0,0,0.1), 0 8px 10px -6px rgba(0,0,0,0.1)",
                  }}
                >
                  <Typography variant="h3">Total Projects</Typography>
                  <Box sx={{ pt: "12px", textAlign: "center", fontWeight: "bold", fontSize: "24px" }}>{dashboardProjectStatus?.projectCount}</Box>
                </Paper>
              )}
            </Grid>

            <ProjectStatusCard gridItem={4} title="Project Closing" count={dashboardProjectStatus?.projectClosing} />
            <ProjectStatusCard gridItem={4} title="Project Cancel" count={dashboardProjectStatus?.projectCancel} />
            <ProjectStatusCard gridItem={2.4} title="Usulan" count={dashboardProjectStatus?.usulan} />
            <ProjectStatusCard gridItem={2.4} title="Reviewer" count={dashboardProjectStatus?.reviewer} />
            <ProjectStatusCard gridItem={2.4} title="Decision Gate" count={dashboardProjectStatus?.decisionGate} />
            <ProjectStatusCard gridItem={2.4} title="FID" count={dashboardProjectStatus?.fid} />
            <ProjectStatusCard gridItem={2.4} title="In Progress" count={dashboardProjectStatus?.inProgress} />

            {dashboardBarRealization && (
              <>
                <Grid item xs={6}>
                  <Paper
                    sx={{
                      height: "470px",
                      borderRadius: "10px",
                      p: "12px 20px 20px 20px",
                      boxShadow: "0 20px 25px -5px rgba(0,0,0,0.1), 0 8px 10px -6px rgba(0,0,0,0.1)",
                    }}
                  >
                    <Typography>Project Budget</Typography>
                    <Typography
                      sx={{ color: dashboardBarRealization.currently > 100 ? "red" : "#7CC444" }}
                      variant="h3"
                    >{`Current Progress: ${dashboardBarRealization.currently.toLocaleString()}% (${
                      dashboardBarRealization.currently > 100 ? "Over Budget" : "On Budget"
                    })`}</Typography>
                    <BarChartGrid
                      isCost
                      title="Project Budget"
                      currently={dashboardBarRealization.currently}
                      labels={dashboardBarRealization.labels}
                      name="Cost"
                      series={dashboardBarRealization.series}
                    />
                  </Paper>
                </Grid>

                {!!dashboardBarRealizationPlanDetail && (
                  <DynamicBarDialog
                    isCost
                    labels={dashboardBarRealizationPlanDetail.labels}
                    series={dashboardBarRealizationPlanDetail.series}
                    name="Cost"
                    openDialog={!!dataPointIndexBarChart}
                  />
                )}
              </>
            )}

            <Grid item xs={6}>
              <Paper
                sx={{
                  width: "100%",
                  height: "470px",
                  borderRadius: "10px",
                  p: "12px 20px 20px 20px",
                  boxShadow: "0 20px 25px -5px rgba(0,0,0,0.1), 0 8px 10px -6px rgba(0,0,0,0.1)",
                }}
              >
                {dashboardPieOverdue && (
                  <>
                    <Typography>Task Summary</Typography>
                    <DetailedPieChart
                      title="Task Summary"
                      details={dashboardPieOverdue.details ?? []}
                      labels={dashboardPieOverdue.labels}
                      series={dashboardPieOverdue.series}
                    />
                  </>
                )}
                <hr />
                {dashboardPieCategory && (
                  <>
                    <Typography>Category</Typography>
                    <ChartGrid
                      extendsChartOptions={{
                        height: "100%",
                      }}
                      extendsOptions={{
                        legend: {
                          formatter: function (val: any, opts: any) {
                            return `${val} -  ${opts.w.globals.series[opts.seriesIndex]}`;
                          },
                        },
                      }}
                      title="Category Summary"
                      series={dashboardPieCategory.series}
                      labels={dashboardPieCategory.labels}
                    />
                  </>
                )}
              </Paper>
            </Grid>

            {dashboardBarProjectSummary && (
              <>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      height: "470px",
                      borderRadius: "10px",
                      p: "12px 20px 20px 20px",
                      boxShadow: "0 20px 25px -5px rgba(0,0,0,0.1), 0 8px 10px -6px rgba(0,0,0,0.1)",
                    }}
                  >
                    <Typography>Project Summary</Typography>
                    <Typography variant="h3">{`Total Projects Last 10 Years - ${dashboardBarProjectSummary.currently} Projects`}</Typography>
                    <BarChartGrid
                      title="Project Summary"
                      currently={dashboardBarProjectSummary.currently}
                      labels={dashboardBarProjectSummary.labels}
                      name="Total Projects"
                      series={dashboardBarProjectSummary.series}
                    />
                  </Paper>
                </Grid>
                {!projectBudgetSeries.includes(dataPointIndexBarChart) && (
                  <DynamicBarDialog labels={[]} series={[]} name="Total Projects" openDialog={!!dataPointIndexBarChart} />
                )}
              </>
            )}
          </Grid>
        </>
      )}
    </Stack>
  );
}

export default observer(Home);
