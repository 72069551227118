import { Box, Grid, Paper, Typography } from "@mui/material";

type TProjectStatusCard = {
  title: string;
  count?: number;
  gridItem: number;
};

export default function ProjectStatusCard({ gridItem, title, count = 0 }: TProjectStatusCard) {
  return (
    <Grid item xs={gridItem}>
      <Paper
        sx={{
          borderRadius: "12px",
          p: "10px 20px 20px 20px",
          boxShadow: "0 20px 25px -5px rgba(0,0,0,0.1), 0 8px 10px -6px rgba(0,0,0,0.1)",
        }}
      >
        <Typography variant="h3">{title}</Typography>
        <Box sx={{ pt: "12px", textAlign: "center", fontWeight: "bold", fontSize: "24px" }}>{count}</Box>
      </Paper>
    </Grid>
  );
}
