import { StyledDataGrid } from "../../../../app/component/StyledDataGrid";
import { NearbyErrorOutlined, Search, VisibilityOutlined } from "@mui/icons-material";
import { Box, IconButton, LinearProgress, Stack, TextField } from "@mui/material";
import { StyledChip } from "../../../../app/component/StyledChip";
import { useStore } from "../../../../app/stores/store";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { history } from "../../../..";
import { ProjectStatus } from "../../../../app/config/enum";
import Progress from "../tabs/fid/tabs/components/Progress";
import { useDebounce } from "../../../../app/hook/useDebounce";

function ProposalDataGrid({
  projectCategoryId,
  projectCategory,
  projectType,
}: {
  projectCategoryId: string;
  projectCategory: string;
  projectType: string;
}) {
  const { project, getProject, queryparams, projectLoading, setQueryParams } = useStore().projectManagementStore;
  const [search, setSearch] = useState<string>();

  const debounce = useDebounce(2000, (e) => setSearch(e.target.value));

  useEffect(() => {
    getProject(projectCategoryId, projectType, search);
  }, [getProject, projectCategoryId, projectType, search]);

  return (
    <Stack>
      <TextField
        onChange={debounce}
        placeholder="Search..."
        sx={{ mb: "16px" }}
        fullWidth
        InputProps={{
          startAdornment: <Search sx={{ mr: "12px" }} />,
        }}
      />
      <Box height={500}>
        <StyledDataGrid
          checkboxSelection
          disableSelectionOnClick
          columns={[
            {
              field: "title",
              headerName: "Title",
              sortable: false,
              disableColumnMenu: true,
              minWidth: 300,
            },
            {
              field: "status",
              headerName: "Status",
              minWidth: 250,
              sortable: false,
              disableColumnMenu: true,
              renderCell: (params) => {
                const IN_PROGRESS = [ProjectStatus.Construction, ProjectStatus.Procurement, ProjectStatus.Preparation];
                let step = 0;

                switch (params.row.status) {
                  case ProjectStatus.Proposal:
                    step = 1;
                    break;
                  case ProjectStatus.Reviewer:
                    step = 2;
                    break;
                  case ProjectStatus.DecisionGate:
                    step = 3;
                    break;
                  case ProjectStatus.Fid:
                    step = 4;
                    break;
                  case ProjectStatus.Preparation:
                    step = 5;
                    break;
                  case ProjectStatus.Procurement:
                    step = 6;
                    break;
                  case ProjectStatus.Construction:
                    step = 7;
                    break;
                  case ProjectStatus.ProjectClosing:
                    step = 8;
                    break;
                  default:
                    step = 0;
                }

                const isShowWarning = params.row.costRealizationPersentase > 115;

                return (
                  <StyledChip
                    color={isShowWarning ? "error" : "primary"}
                    icon={
                      <Box
                        sx={{
                          bgcolor: ({ palette }) => (isShowWarning ? palette.error.main : palette.primary.main),
                          width: "24px",
                          height: "24px",
                          borderRadius: "24px",
                          color: "white !important",
                          fontWeight: 700,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexShrink: 0,
                        }}
                      >
                        {params.row.isReFID ? <NearbyErrorOutlined sx={{ width: 13, height: 13 }} /> : step}
                      </Box>
                    }
                    label={params.row.isReFID ? "Re-FID" : IN_PROGRESS.includes(params.row.status) ? "IN PROGRESS" : params.row.status}
                  />
                );
              },
            },
            {
              field: "ubName",
              headerName: "Owner Project",
              width: 250,
              sortable: false,
              disableColumnMenu: true,
            },
            {
              field: "cost",
              headerName: "Cost",
              width: 250,
              sortable: false,
              disableColumnMenu: true,
              renderCell: (params) => "Rp " + params.row.cost.toLocaleString(),
            },
            {
              field: "costRealizationPersentase",
              headerName: "Cost Realization (%)",
              width: 200,
              sortable: false,
              disableColumnMenu: true,
              renderCell: (params) => <Progress data={params.row.costRealizationPersentase.toFixed(2)} />,
            },
            {
              field: "costRealizationRupiah",
              headerName: "Cost Realization (Rp)",
              width: 200,
              sortable: false,
              disableColumnMenu: true,
              renderCell: (params) => (
                <Box sx={{ width: "120px", textAlign: "center" }}>
                  <LinearProgress sx={{}} variant="determinate" value={(params.row.costRealizationRupiah / params.row.cost) * 100} />
                  <span style={{ fontSize: "12px" }}>Rp {params.row.costRealizationRupiah.toLocaleString()}</span>
                </Box>
              ),
            },
            {
              field: "progressRealizationPersentase",
              headerName: "Progress Realization (%)",
              width: 200,
              sortable: false,
              disableColumnMenu: true,
              renderCell: (params) => <Progress data={params.row.progressRealizationPersentase.toFixed(2)} />,
            },
            {
              field: "action",
              headerName: "",
              width: 24,
              renderCell: (params) => (
                <IconButton
                  onClick={() => {
                    history.push(
                      `/project-monitoring/proposal/list/form?projectCategory=${params.row.category}&id=${params.row.id}&status=${
                        params.row.status
                      }&reFID=${params.row.isReFID ? 1 : 0}`
                    );
                  }}
                >
                  <VisibilityOutlined sx={{ color: "black" }} />
                </IconButton>
              ),
              sortable: false,
            },
          ]}
          rows={project.data}
          onPageSizeChange={(v) => setQueryParams("pageSize", v)}
          onPageChange={(v) => setQueryParams("currentPage", v)}
          pagination
          rowCount={project.rowCount}
          paginationMode="server"
          rowsPerPageOptions={[5, 25, 50, 100]}
          pageSize={queryparams.pageSize}
          page={queryparams.currentPage}
          loading={projectLoading}
        />
      </Box>
    </Stack>
  );
}

export default observer(ProposalDataGrid);
