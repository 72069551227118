import { Box, Paper } from "@mui/material";
import DashboardLayoutHeader from "../../../app/component/layout/DashboardLayoutHeader";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ProjectCategory, ProjectStatus } from "../../../app/config/enum";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import CollapsableDataList from "./components/CollapsableDataList";

// projectCategory props type is any because it's dynamic
export type TProposalIndexPageProps = {
  projectCategory: string;
  projectCategoryId: string;
};

export default function ProposalIndexPage({ projectCategory, projectCategoryId }: TProposalIndexPageProps) {
  const { setQueryParams } = useStore().projectManagementStore;

  const route =
    (projectCategory === ProjectCategory.BD && `/project-monitoring/proposal/list/${ProjectCategory.BD}`) ||
    (projectCategory === ProjectCategory.NonBD && `/project-monitoring/proposal/list/${ProjectCategory.NonBD}`) ||
    (projectCategory === ProjectCategory.Anorganik && `/project-monitoring/proposal/list/${ProjectCategory.Anorganik}`) ||
    "";

  const categoryLabel =
    (projectCategory === ProjectCategory.BD && "Business Development") ||
    (projectCategory === ProjectCategory.NonBD && "Non-Business Development") ||
    (projectCategory === ProjectCategory.Anorganik && "Anorganik") ||
    "";

  useEffect(() => {
    setQueryParams("projectType", projectCategory);
  }, [projectCategory, setQueryParams]);

  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Proposal",
            href: route,
          },
          {
            label: "Proposal List",
            href: route,
          },
          {
            label: categoryLabel,
            href: route,
          },
        ]}
        pageTitle="Project Monitoring"
      >
        <Link to={`/project-monitoring/proposal/list/form/?status=${ProjectStatus.Proposal}&projectCategory=${projectCategory}`}>
          <DashboardLayoutHeader.Button startIcon={<Add />} variant="contained">
            Add Proposal
          </DashboardLayoutHeader.Button>
        </Link>
      </DashboardLayoutHeader>
      <Paper sx={{ p: "16px", boxShadow: "none" }}>
        <CollapsableDataList projectCategory={projectCategory} projectCategoryId={projectCategoryId} />
      </Paper>
    </Box>
  );
}
