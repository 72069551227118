import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { StyledDataGridPremium } from "../../../../../../app/component/StyledDataGridPremium";
import useQuery from "../../../../../../app/hook/useQuery";
import { Box, Stack, Typography } from "@mui/material";
import { useStore } from "../../../../../../app/stores/store";
import { format } from "date-fns";
import { convertDataToPreviewGridExcel } from "../../../../../../app/utils/utils";
import { GridEventListener, GridRowModel, GridRowEditStopReasons, GridRowModesModel, GridRowsProp } from "@mui/x-data-grid-premium";

type TExcelDataGrid = {
  setExcelDataPreview: Dispatch<SetStateAction<any>>;
};

export const ExcelDataGrid = ({ setExcelDataPreview }: TExcelDataGrid) => {
  const { modalStore, milestonetore } = useStore();
  const { loadingUploadProduct, previewDataExcel } = milestonetore;
  const [gridColumnHelper, setGridColumnHelper] = useState<any[]>([]);
  const [gridGroupingHelperCost, setGridGroupingHelperCost] = useState<any>([]);
  const [gridGroupingHelperProgress, setGridGroupingHelperProgress] = useState<any>([]);
  const [gridGroupingNewData, setGridGroupingNewData] = useState<any>([]);
  const query = useQuery();
  const id = query.get("id");

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setGridGroupingNewData(
      gridGroupingNewData.map((row: any) => (row.milestonesId + row.taskId === newRow.milestonesId + newRow.taskId ? updatedRow : row))
    );

    return updatedRow;
  };

  useEffect(() => {
    if (!previewDataExcel || previewDataExcel.data.length === 0) return;

    const { tempColumnHelper, tempGroupingHelperProgress, tempGroupingHelperCost, tempGroupingNewData } = convertDataToPreviewGridExcel(
      previewDataExcel.data
    );

    setGridColumnHelper(tempColumnHelper);
    setGridGroupingHelperCost(tempGroupingHelperCost);
    setGridGroupingHelperProgress(tempGroupingHelperProgress);
    setGridGroupingNewData(tempGroupingNewData);
  }, [previewDataExcel, setExcelDataPreview]);

  useEffect(() => {
    setExcelDataPreview(gridGroupingNewData);
  }, [gridGroupingNewData, setExcelDataPreview]);

  return (
    <Box>
      <Stack direction="row" gap="12px" sx={{ alignItems: "center", justifyContent: "space-between", height: "100%", my: "24px" }}>
        <Typography color="black" fontWeight={700} fontSize="20px">
          Preview Data
        </Typography>
      </Stack>
      <StyledDataGridPremium
        getRowId={(row) => row.milestonesId + row.taskId + row.subTaskId}
        disableRowSelectionOnClick
        autoHeight
        editMode="row"
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ columnGrouping: true }}
        initialState={{
          pinnedColumns: {
            left: ["milestones", "tasks", "subTask"],
          },
        }}
        columns={[
          {
            field: "milestones",
            headerName: "Milestones",
            disableColumnMenu: true,
            width: 150,
          },
          {
            field: "tasks",
            headerName: "Task",
            disableColumnMenu: true,
            width: 150,
            renderCell: (params) => params.row.tasks || "-",
          },
          {
            field: "subTask",
            headerName: "Sub Task",
            disableColumnMenu: true,
            width: 150,
            renderCell: (params) => params.row.subTask || "-",
          },
          {
            field: "startDate",
            headerName: "Start Date",
            disableColumnMenu: true,
            width: 150,
            renderCell: (params) => format(new Date(params.row.startDate), "MMM yyyy"),
          },
          {
            field: "endDate",
            headerName: "End Date",
            disableColumnMenu: true,
            width: 150,
            renderCell: (params) => format(new Date(params.row.endDate), "MMM yyyy"),
          },
          {
            field: "nominalPagu",
            headerName: "Nominal Pagu",
            disableColumnMenu: true,
            align: "right",
            width: 180,
            renderCell: (params) => params.row.nominalPagu.toLocaleString(),
          },
          {
            field: "percentageToCapex",
            align: "right",
            headerName: "Percentage to CAPEX",
            disableColumnMenu: true,
            width: 170,
            renderCell: (params) => params.row.percentageToCapex,
          },
          ...gridColumnHelper,
        ]}
        columnGroupingModel={[
          {
            groupId: "Progress Planning (%)",
            headerAlign: "center",
            children: gridGroupingHelperProgress.map((item: any) => ({ field: item.field })),
          },
          {
            groupId: "Cost Planning (Rp)",
            headerAlign: "center",
            children: gridGroupingHelperCost.map((item: any) => ({ field: item.field })),
          },
        ]}
        rows={gridGroupingNewData ?? []}
      />
    </Box>
  );
};
