import { makeAutoObservable, runInAction } from "mobx";
import { IPieCategory } from "../models/pieCategory";
import { IPieStatus } from "../models/pieStatus";
import { IPieOverdue } from "../models/pieOverdue";
import agent from "../api/agent";
import { ILineProjectCost } from "../models/lineProjectCost";
import { ILineProjectCount } from "../models/lineProjectCount";
import { IBarRealization, IBarRealizationPlanDetail } from "../models/barRealization";
import { IRecentProject, TProjectsListPayload, TProjectsListResponse, TStatusProjectPayload, TStatusProjectResponse } from "../models/recentProject";
import { TProjectStatus } from "../models/projectStatus";
import { TSCurveSPI } from "../models/spi";

export class DashboardStore {
  dashboardPieCategory: IPieCategory | null = null;
  dashboardPieStatus: IPieStatus | null = null;
  dashboardProjectStatus: TProjectStatus | null = null;
  dashboardPieOverdue: IPieOverdue | null = null;
  dashboardLineProjectCost: ILineProjectCost | null = null;
  dashboardLineProjectCount: ILineProjectCount | null = null;
  dashboardBarRealization: IBarRealization | null = null;
  dashboardBarProjectSummary: IBarRealization | null = null;
  dashboardRecentProject: IRecentProject[] | null = null;
  dashboardBarRealizationPlanDetail: IBarRealizationPlanDetail | null = null;
  sCurveTask: TSCurveSPI | null = null;
  statusTask: TStatusProjectResponse | undefined = undefined;
  projectBudgetTask: IBarRealization | null = null;
  barMilestones: IBarRealization | null = null;
  barMilestonesTask: IBarRealization | null = null;
  projectsList: TProjectsListResponse[] = [];
  projectBudgetDetailTask: IBarRealization | null = null;
  sCurveSpi: TSCurveSPI | null = null;

  dataPointIndexBarChart: string = "";
  dataPointIndexBarChartMilestones = false;
  indexHelperBarChart = 0;

  barMilestonesLoading = false;
  projectBudgetDetailTaskLoading = false;
  dashboardPieCategoryLoading = false;
  dashboardPieStatusLoading = false;
  dashboardBarProjectSummaryLoading = false;
  dashboardProjectStatusLoading = false;
  dashboardPieOverdueLoading = false;
  dashboardLineProjectCostLoading = false;
  dashboardLineProjectCountLoading = false;
  dashboardBarRealizationLoading = false;
  dashboardBarRealizationPlanDetailLoading = false;
  dashboardRecentProjectLoading = false;
  sCurveTaskLoading = false;
  sCurveSpiLoading = false;
  statusTaskLoading = false;
  projectBudgetTaskLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  getProjectsList = async (payload: TProjectsListPayload) => {
    try {
      const res = await agent.Dashboard.getProjectsList(payload);

      runInAction(() => (this.projectsList = res));
    } catch (e) {
      throw e;
    }
  };

  getStatusTask = async (payload: TStatusProjectPayload) => {
    this.statusTaskLoading = true;

    try {
      const res = await agent.Dashboard.getStatusTask(payload);

      runInAction(() => (this.statusTask = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.statusTaskLoading = false));
    }
  };

  getProjectBudgetTask = async (payload: TStatusProjectPayload) => {
    this.projectBudgetTaskLoading = true;
    try {
      const res = await agent.Dashboard.getProjectBudgetTask(payload);

      runInAction(() => (this.projectBudgetTask = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.projectBudgetTaskLoading = false));
    }
  };

  getBarMilestones = async (payload: TStatusProjectPayload, type: "milestones" | "tasks") => {
    this.barMilestonesLoading = true;
    try {
      const res = await agent.Dashboard.getBarMilestones(payload, type);

      runInAction(() => (this.barMilestones = res));
      return res;
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.barMilestonesLoading = false));
    }
  };

  getBarMilestonesTask = async (payload: TStatusProjectPayload, type: "milestones" | "tasks") => {
    this.barMilestonesLoading = true;
    try {
      const res = await agent.Dashboard.getBarMilestones(payload, type);

      return res;
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.barMilestonesLoading = false));
    }
  };

  getProjectBudgetDetailTask = async (payload: TStatusProjectPayload, isPlan: boolean) => {
    this.projectBudgetDetailTaskLoading = true;
    try {
      const res = await agent.Dashboard.getProjectBudgetDetailTask(payload, isPlan);

      runInAction(() => (this.projectBudgetDetailTask = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.projectBudgetDetailTaskLoading = false));
    }
  };

  getSCurveSPI = async (payload: TStatusProjectPayload) => {
    this.sCurveSpiLoading = true;

    try {
      const res = await agent.Dashboard.getSCurveSPI(payload);

      runInAction(() => (this.sCurveSpi = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.sCurveSpiLoading = false));
    }
  };

  getSCurveTask = async (proposalId: string, taskId: string) => {
    this.sCurveTaskLoading = true;
    try {
      const res = await agent.Dashboard.getSCurveTask(proposalId, taskId);

      runInAction(() => (this.sCurveTask = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.sCurveTaskLoading = false));
    }
  };

  getDashboardPieCategory = async (params?: any) => {
    this.dashboardPieCategoryLoading = true;
    try {
      const res = await agent.Dashboard.getPieCategory(params);

      runInAction(() => (this.dashboardPieCategory = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.dashboardPieCategoryLoading = false));
    }
  };

  getDashboardProjectStatus = async (params?: any) => {
    this.dashboardProjectStatusLoading = true;

    try {
      const res = await agent.Dashboard.getProjectStatus(params);

      runInAction(() => (this.dashboardProjectStatus = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.dashboardProjectStatusLoading = false));
    }
  };

  getDashboardPieStatus = async () => {
    this.dashboardPieStatusLoading = true;
    try {
      const res = await agent.Dashboard.getPieStatus();

      runInAction(() => (this.dashboardPieStatus = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.dashboardPieStatusLoading = false));
    }
  };

  getDashboardPieOverdue = async (params?: any) => {
    this.dashboardPieOverdueLoading = true;
    try {
      const res = await agent.Dashboard.getPieOverdue(params);

      runInAction(() => (this.dashboardPieOverdue = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.dashboardPieOverdueLoading = false));
    }
  };

  getDashboardLineProjectCount = async (params?: any) => {
    this.dashboardLineProjectCountLoading = true;
    try {
      const res = await agent.Dashboard.getLineProjectCount(params);

      runInAction(() => (this.dashboardLineProjectCount = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.dashboardLineProjectCountLoading = false));
    }
  };

  getDashboardLineProjectCost = async () => {
    this.dashboardLineProjectCostLoading = true;
    try {
      const res = await agent.Dashboard.getLineProjectCost();

      runInAction(() => (this.dashboardLineProjectCost = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.dashboardLineProjectCostLoading = false));
    }
  };

  getDashboardBarProjectSummary = async (params?: any) => {
    this.dashboardBarProjectSummaryLoading = true;
    try {
      const res = await agent.Dashboard.getBarProjectSummary(params);

      runInAction(() => (this.dashboardBarProjectSummary = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.dashboardBarProjectSummaryLoading = false));
    }
  };

  getDashboardBarRealization = async (params?: any) => {
    this.dashboardBarRealizationLoading = true;
    try {
      const res = await agent.Dashboard.getBarRealization(params);

      runInAction(() => (this.dashboardBarRealization = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.dashboardBarRealizationLoading = false));
    }
  };

  getDashboardBarRealizationPlanDetail = async (identifier: string) => {
    this.dashboardBarRealizationPlanDetailLoading = true;
    try {
      const res = await agent.Dashboard.getDashboardBarRealizationPlanDetail(identifier);

      runInAction(() => (this.dashboardBarRealizationPlanDetail = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.dashboardBarRealizationPlanDetailLoading = false));
    }
  };

  getDashboardRecentProject = async (params?: any) => {
    this.dashboardRecentProjectLoading = true;
    try {
      const res = await agent.Dashboard.getRecentProject(params);

      runInAction(() => (this.dashboardRecentProject = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.dashboardRecentProjectLoading = false));
    }
  };

  // INDETIFIER --> 0 = PLAN, 1 = REALIZATION
  setDataPointIndexBarChart = (identifier: string) => {
    this.dataPointIndexBarChart = identifier === this.dataPointIndexBarChart ? "" : identifier;
  };

  setDataPointIndexBarChartMilestones = (openDialog: boolean) => {
    this.dataPointIndexBarChartMilestones = openDialog;
  };

  setBarChartMilestonesTasks = (data: IBarRealization) => {
    this.barMilestonesTask = data;
  };

  setIndexHelperBarChart = (index: number) => {
    this.indexHelperBarChart = index;
  };
}
