import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { history } from "../..";
import { IExample } from "../models/example";
import { IAccountInfo, IAccountLoginValues, IAccountRegisterValues } from "../models/account";
import { store } from "../stores/store";
import { IUser, IUserDetail, IUserOptions, IUserPhoto } from "../models/user";
import { DataGridResult } from "../models/materialUI/dataGrid";
import { NO_SUBTASK_ID, ProjectCategory, ProjectStatus, Roles } from "../config/enum";
import { ILand, ILandDetail, ILandStatus, ILandType } from "../models/land";
import { IProjectType, IProjectTypeBody } from "../models/projectType";
import { ILandPayload, ILandDelete, ILandGridResponse } from "../models/land";
import { Project } from "../models/project";
import { IRegional, IRegionalBody } from "../models/regional";
import { IUnitBusiness, IUnitBusinessBody, IUnitBusinessGrid, IUnitBusinessQueryParams } from "../models/unitBusiness";
import { ISubHolding, ISubHoldingBody, ISubHoldingGrid, ISubHoldingQueryParams } from "../models/subHoldings";
import {
  IProposalGeneralInformation,
  IProposalGeneralInformationBody,
  IProposalGeneralInformationEditBody,
  IProposalGeneralInformationResponse,
} from "../models/proposalGeneralInformation";
import { IProposalLand, IProposalLandBody, IProposalLandEditBody, IProposalLandResponse } from "../models/proposalLand";
import {
  IProposalProductAnalysis,
  IProposalProductAnalysisBody,
  IProposalProductAnalysisEditBody,
  IProposalProductAnalysisResponse,
} from "../models/proposalProductAnalysis";
import {
  IProposalMarketAnalysis,
  IProposalMarketAnalysisBody,
  IProposalMarketAnalysisEditBody,
  IProposalMarketAnalysisResponse,
} from "../models/proposalMarketAnalysis";
import {
  IProposalCostplanAnalysis,
  IProposalCostplanAnalysisBody,
  IProposalCostplanAnalysisEditBody,
  IProposalCostplanAnalysisResponse,
} from "../models/proposalCostplanAnalysis";
import {
  IProposalCollaborationAnalysis,
  IProposalCollaborationAnalysisBody,
  IProposalCollaborationAnalysisEditBody,
  IProposalCollaborationAnalysisResponse,
} from "../models/proposalCollaborationAnalysis";
import {
  IProposalEconomyAnalysis,
  IProposalEconomyAnalysisBody,
  IProposalEconomyAnalysisEditBody,
  IProposalEconomyAnalysisResponse,
} from "../models/proposalEconomyAnalysis";
import {
  IProposalFeasibilityStudy,
  IProposalFeasibilityStudyBody,
  IProposalFeasibilityStudyEditBody,
  IProposalFeasibilityStudyResponse,
} from "../models/proposalFeasibilityStudy";
import { IProposalFID, IProposalFIDResponse } from "../models/proposalFID";
import { IApproval } from "../models/approval";
import { IProposalAttachment, IProposalAttachmentResponse } from "../models/proposalAttachment";
import { IProjectCategory } from "../models/projectCategory";
import { IUnderlying, IUnderlyingBody, IUnderlyingQueryParams } from "../models/underlying";
import { IAssetCategory, IAssetCategoryBody, IAssetCategoryQueryParams } from "../models/assetCategory";
import { IInventoryCategory, IInventoryCategoryBody, IInventoryCategoryQueryParams } from "../models/inventoryCategory";
import { IOwnershipCategory, IOwnershipCategoryBody, IOwnershipCategoryQueryParams } from "../models/ownershipCategory";
import { IProposalRisk, IProposalRiskBody, IProposalRiskEditBody } from "../models/proposalProposalRisk";
import { IProposalLegal, IProposalLegalBody, IProposalLegalEditBody } from "../models/proposalProposalLegal";
import { IProposalPermission, IProposalPermissionBody, IProposalPermissionEditBody } from "../models/proposalPermission";
import { IProposalScopeOfWork, IProposalScopeOfWorkBody, IProposalScopeOfWorkEditBody } from "../models/proposalScopeOfWork";
import {
  IProposalBackgroundObjective,
  IProposalBackgroundObjectiveBody,
  IProposalBackgroundObjectiveEditBody,
} from "../models/proposalBackgroundObjective";
import { IProposalTimelineBody, IProposalTimelineEditBody } from "../models/proposalTimeline";
import { IProposalDrawdown, IProposalDrawdownBody, IProposalDrawdownEditBody } from "../models/proposalDrawdown";
import { IProposalConstructor, IProposalConstructorBody, IProposalConstructorEditBody } from "../models/proposalConstructor";
import { IProposalMember, IProposalMemberBody } from "../models/proposalMember";
import { IProposalPsRiskMitigation, IProposalPsRiskMitigationBody, IProposalPsRiskMitigationEditBody } from "../models/proposalPsRiskMitigation";
import { IProposalTechnicalAnalysis, IProposalTechnicalAnalysisBody, IProposalTechnicalAnalysisEditBody } from "../models/proposalTechnicalAnalysis";
import {
  IFidMilestone,
  IFidMilestoneBody,
  IFidMilestoneQueryParams,
  IFidMilestoneSubTaskBody,
  IFidMilestoneTaskBody,
  IFidTask,
  PreviewDataExcel,
  TFinancialPlan,
  TFinancialPlanBody,
  TPhysicalPlan,
  TPhysicalPlanBody,
  TTotalMilestonestasks,
} from "../models/fidMilestones";
import { IProgressRealization, IProgressRealizationBody, IProgressRealizationHistory, TNewProgressRealization } from "../models/progressRealization";
import { TOrganization, TOrganizationQueryParams, TSelectedOrganization } from "../models/organization";
import { IProposalReviewer } from "../models/proposalReviewer";
import { TPreparation, TPreparationResponse, TProposalPreparationBody } from "../models/proposalPreparation";
import { TPreparationPermission, TPreparationPermissionResponse, TProposalPreparationPermissionBody } from "../models/proposalPreparationPermission";
import { TConceptSketch, TConceptSketchResponse, TProposalConceptSketchBody } from "../models/proposalConceptSketch";
import { TProposalSchematicDrawingBody, TSchematicDrawing, TSchematicDrawingResponse } from "../models/proposalSchematicDrawing";
import { TDesignBody, TDesignResponse } from "../models/proposalDesgin";
import { TSelectedSettingApproval, TSettingApproval, TSettingApprovalQueryParams } from "../models/settingApproval";
import { IProposalLog, TProposalRefidHistory } from "../models/proposalLog";
import { TCostRealization, TCostRealizationBody } from "../models/costRealization";
import { TChangeRequest, TChangeRequestGrid } from "../models/changeRequest";
import { IWBS } from "../models/wbs";
import { TReviewerAttachmentLogPayload, TReviewerLog, TReviewerLogEditPayload } from "../models/reviewerLog";
import {
  IProposalPsBackgroundObjective,
  IProposalPsBackgroundObjectiveBody,
  IProposalPsBackgroundObjectiveEditBody,
} from "../models/proposalPsBackgroundObjective";
import { ITask } from "../models/task";
import { ITaskSubTaskAttachment, TTaskAttachmentSuperadmin } from "../models/taskSubTaskAttachment";
import { IFile } from "../models/file";
import { TPeruntukan, TPeruntukanBody, TPeruntukanGrid, TPeruntukanQueryParams } from "../models/peruntukan";
import { request } from "http";
import { TStepBarPercentage } from "../models/stepBarPercentage";
import { IUnitBusinessCategory, IUnitBusinessCategoryBody, IUnitBusinessCategoryGrid } from "../models/unitBusinessCategory";
import { IProposalKajianFS, IProposalKajianFSResponse } from "../models/proposalKajianFs";
import { IRefid, IGeneralReFid, IFidMilestoneTaskReFIDBody, TApproveRefuseReFIDBody } from "../models/reFid";
import { IComment } from "../models/comment";
import { TConsolidation, TConsolidationCost } from "../models/consolidation";
import { INotification } from "../models/notification";
import { IPieOverdue } from "../models/pieOverdue";
import { IPieStatus } from "../models/pieStatus";
import { IPieCategory } from "../models/pieCategory";
import { ILineProjectCost } from "../models/lineProjectCost";
import { ILineProjectCount } from "../models/lineProjectCount";
import { IBarRealization } from "../models/barRealization";
import { IRecentProject, TProjectsListPayload, TProjectsListResponse, TStatusProjectPayload, TStatusProjectResponse } from "../models/recentProject";
import { IProjectCategoryNBD } from "../models/projectCategoryNDB";
import { ISKTL } from "../models/sktl";
import { TAmandement, TAmandementQueryParams, TApproveRejectAmandementBody } from "../models/amandement";
import { TSCurveSPI, TSpi } from "../models/spi";
import { TAuctionStatus, TAuctionStatusQueryParams, TSelectedAuctionStatus } from "../models/auctionStatus";
import { TProjectStatus } from "../models/projectStatus";
import { TProjectCategoryAll, TProjectCategoryAllBody } from "../models/projectCategoryAll";
import { TGeneralLog, TGeneralLogQueryParams } from "../models/generalLog";
import { AbiGridResponse, AbiLockPayload, TTotalAbiResponse } from "../models/abi";
import { TDownloadTemplateProjectAdminPayload, TUploadTemplateResponse } from "../models/uploadProjectAdmin";

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    if (process.env.NODE_ENV === "development") await sleep(200);
    response = readResponseHeader(response);
    return response;
  },
  (error: AxiosError<any>) => {
    const { data, status, config } = error.response!;
    console.log(error.response!);

    switch (status) {
      case 400:
        if (typeof data === "string") {
          store.snackbarStore.show("error", data);
        }
        if (config.method === "get" && data.errors.hasOwnProperty("id")) {
          history.push("/not-found");
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(" " + data.errors[key]);
            }
          }
          // throw modalStateErrors.flat();
          store.snackbarStore.show("error", modalStateErrors.join());
        }
        break;
      case 401: {
        store.snackbarStore.show("error", "Error 401 (Unauthorized)");
        history.push("/auth/login");
        break;
      }
      case 403:
        store.snackbarStore.show("error", "Error 403 (Forbidden)");
        break;
      case 404:
        history.push("/not-found");
        break;
      case 500:
        store.commonStore.setServerError(data);
        history.push("/server-error");
        break;
    }
    return Promise.reject(error);
  }
);

const readResponseHeader = (response: AxiosResponse): AxiosResponse => {
  if (response.headers["rowcount"]) {
    // data grid result
    response.data = new DataGridResult(response.data, parseInt(response.headers["rowcount"]));
    return response as AxiosResponse<DataGridResult<any>>;
  }
  return response;
};

export const handleBlobResponse = (response: AxiosResponse<Blob>) => {
  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = href;
  const filename = response.headers["content-disposition"].split('"')[1];
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string, body?: {}) => axios.get<T>(url, body).then(responseBody),
  getFile: (url: string, body?: AxiosRequestConfig<any>) =>
    axios.get<Blob>(url, { ...body, responseType: "blob" }).then((response) => handleBlobResponse(response)),
  getFileWithDifferentResponse: (url: string, body?: AxiosRequestConfig<any>) =>
    axios.get<any>(url, { ...body, responseType: "blob" }).then((response: any) => handleBlobResponse(response.bytes)),
  post: <T>(url: string, body?: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body?: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string, body?: {}) => axios.delete<T>(url, { data: body }).then(responseBody),
  putFile: <T>(url: string, body?: {}) => axios.put<T>(url, body, { headers: { "Content-Type": "multipart/form-data" } }).then(responseBody),
  postFile: <T>(url: string, body?: {}) => axios.post<T>(url, body, { headers: { "Content-Type": "multipart/form-data" } }).then(responseBody),
  getFileWithPost: (url: string, body?: {}) => axios.post<Blob>(url, body, { responseType: "blob" }).then((response) => handleBlobResponse(response)),
};

const Account = {
  current: () => requests.get<IAccountInfo>("/account"),
  login: (user: IAccountLoginValues) => requests.post<IAccountInfo>("/account/login", user),
  register: (user: IAccountRegisterValues) => requests.post<IAccountInfo>("/account/register", user),
};

const User = {
  list: (params: URLSearchParams) => requests.get<DataGridResult<IUser[]>>("/user/grid", { params }),
  getOptions: (params?: { search: string }) => requests.get<IUserOptions[]>("/user", { params }),
  detail: (username: string) => requests.get<IUserDetail>(`/user/${username}`),
  create: (user: IUser) => requests.post<void>("/user", user),
  edit: (user: IUser) => requests.put<void>(`/user/${user.username}`, user),
  delete: (username: string) => requests.del<void>(`/user/${username}`),
  upload: (data: IUserPhoto) => requests.postFile<void>(`/user/${data.username}/photo`, data.file),
  addRole: (username: string, role: Roles) => requests.post<void>(`/user/${username}/role`, { name: role }),
  removeRole: (username: string, role: Roles) => requests.del<void>(`/user/${username}/role`, { name: role }),
  resetPass: (username: string) => requests.put<void>(`/user/${username}/reset`),
};

const Organization = {
  list: (level?: string) => requests.get<TOrganization[]>("/organizations", { params: { level: level } }),
  getOrganizationsByProposalId: (proposalId: string) => requests.get<TOrganization[]>(`/organizations/member/${proposalId}`),
};

const Role = {};

const Example = {
  list: (params: URLSearchParams) => requests.get<IExample[]>("/example", { params }), // name must exactly "params"
  listGrid: (params: URLSearchParams) => requests.get<DataGridResult<IExample[]>>("/example/grid", { params }), // because it's a field called "params"
  detail: (id: string) => requests.get<IExample>(`/example/${id}`),
  create: (example: IExample) => requests.post<void>("/example", example),
  edit: (example: IExample) => requests.put<void>(`/example/${example.id}`, example),
  delete: (id: string) => requests.del<void>(`/example/${id}`),
};

const Land = {
  list: () => requests.get<ILand[]>("/Land"),
  listGrid: (params: URLSearchParams) => requests.get<ILandGridResponse>("/Land/grid", { params }),
  delete: (data: ILandDelete[]) => requests.del<void>(`/land`, data),
  add: (body: ILandPayload) => requests.post("/land", body),
  edit: (landId: string, body: ILandPayload) => requests.put(`/land/${landId}`, body),
  detail: (landId: string) => requests.get<ILandDetail>(`/land/${landId}`),
  getType: () => requests.get<ILandType[]>("/landTypes"),
  getStatus: () => requests.get<ILandStatus[]>("/landStatus"),
};

const ChangeRequest = {
  listGrid: (params: URLSearchParams) => requests.get<TChangeRequestGrid>("/changeRequests/grid", { params }),
  userAction: (changeRequestId: string, userAction: string) => requests.put(`/changeRequests/${changeRequestId}/${userAction}`),
  detail: (changeRequestId: string) => requests.get<TChangeRequest>(`/changeRequests/${changeRequestId}`),
};

const ProjectManagement = {
  projectGrid: (params: any) => requests.get<DataGridResult<Project[]>>("/projects/grid", { params }),

  getApprovalGrid: (params: any) => requests.get<DataGridResult<IApproval[]>>(`/Projects/approval/grid`, { params }),

  reviewerApproveRefuse: (proposalId: string, body: any) => requests.put(`/projects/${proposalId}/reviewer`, body),
  decisionGateApproveRefuse: (proposalId: string, body: any) => requests.put(`/projects/${proposalId}/decisionGate`, body),
};

const Proposal = {
  addGeneralInformation: (body: IProposalGeneralInformationBody) =>
    requests.post<IProposalGeneralInformationResponse>("/proposal/generalInformation", body),
  getGeneralInformation: (proposalId: string) => requests.get<IProposalGeneralInformation>(`/proposal/generalInformation/${proposalId}`),
  editGeneralInformation: (proposalId: string, body: Partial<IProposalGeneralInformationEditBody>) =>
    requests.put<IProposalGeneralInformationResponse>(`/proposal/generalInformation/${proposalId}`, body),

  addLand: (proposalId: string, body: IProposalLandBody) => requests.post<IProposalLandResponse>(`/proposal/${proposalId}/land`, body),
  getLand: (proposalId: string) => requests.get<IProposalLand[]>(`/proposal/${proposalId}/land`),
  editLand: (proposalId: string, body: IProposalLandEditBody) => requests.put<IProposalLandResponse>(`/proposal/${proposalId}/land`, body),

  addProductAnalysis: (proposalId: string, body: IProposalProductAnalysisBody[]) =>
    requests.post<IProposalProductAnalysisResponse>(`/projects/${proposalId}/productAnalysis`, body),
  getProductAnalysis: (proposalId: string) => requests.get<IProposalProductAnalysis[]>(`/projects/${proposalId}/productAnalysis`),
  editProductAnalysis: (proposalId: string, body: IProposalProductAnalysisEditBody[]) =>
    requests.put<IProposalProductAnalysisResponse>(`/projects/${proposalId}/productAnalysis`, body),

  addPreparation: (proposalId: string, body: TProposalPreparationBody[]) =>
    requests.post<TPreparationResponse>(`/projects/${proposalId}/preparations`, body),
  getPreparation: (proposalId: string) => requests.get<TPreparation[]>(`/projects/${proposalId}/preparations`),
  editPreparation: (proposalId: string, body: TProposalPreparationBody[]) =>
    requests.put<TPreparationResponse>(`/projects/${proposalId}/preparations`, body),

  addPreparationPermission: (proposalId: string, body: TProposalPreparationPermissionBody[]) =>
    requests.post<TPreparationPermissionResponse>(`/projects/${proposalId}/permissions`, body),
  getPreparationPermission: (proposalId: string) => requests.get<TPreparationPermission[]>(`/projects/${proposalId}/permissions`),
  editPreparationPermission: (proposalId: string, body: TProposalPreparationPermissionBody[]) =>
    requests.put<TPreparationPermissionResponse>(`/projects/${proposalId}/permissions`, body),

  addConceptSketch: (proposalId: string, body: TProposalConceptSketchBody[]) =>
    requests.post<TConceptSketchResponse>(`/projects/${proposalId}/conceptSketch`, body),
  getConceptSketch: (proposalId: string) => requests.get<TConceptSketch[]>(`/projects/${proposalId}/conceptSketch`),
  editConceptSketch: (proposalId: string, body: TProposalConceptSketchBody[]) =>
    requests.put<TConceptSketchResponse>(`/projects/${proposalId}/conceptSketch`, body),

  addSchematicDrawing: (proposalId: string, body: TProposalSchematicDrawingBody[]) =>
    requests.post<TSchematicDrawingResponse>(`/projects/${proposalId}/schematicDrawing`, body),
  getSchematicDrawing: (proposalId: string) => requests.get<TSchematicDrawing[]>(`/projects/${proposalId}/schematicDrawing`),
  editSchematicDrawing: (proposalId: string, body: TProposalSchematicDrawingBody[]) =>
    requests.put<TSchematicDrawingResponse>(`/projects/${proposalId}/schematicDrawing`, body),

  addDesign: (proposalId: string, body: TDesignBody[]) => requests.post<TDesignResponse>(`/projects/${proposalId}/designMaterial`, body),
  getDesign: (proposalId: string) => requests.get<TDesignResponse[]>(`/projects/${proposalId}/designMaterial`),
  editDesign: (proposalId: string, body: TDesignBody[]) => requests.put<TDesignResponse>(`/projects/${proposalId}/designMaterial`, body),

  addMarketAnalysis: (proposalId: string, body: IProposalMarketAnalysisBody[]) =>
    requests.post<IProposalMarketAnalysisResponse>(`/projects/${proposalId}/marketAnalysis`, body),
  getMarketAnalysis: (proposalId: string) => requests.get<IProposalMarketAnalysis[]>(`/projects/${proposalId}/marketAnalysis`),
  editMarketAnalysis: (proposalId: string, body: IProposalMarketAnalysisEditBody[]) =>
    requests.put<IProposalMarketAnalysisResponse[]>(`/projects/${proposalId}/marketAnalysis`, body),

  addCostplanAnalysis: (proposalId: string, body: IProposalCostplanAnalysisBody[]) =>
    requests.post<IProposalCostplanAnalysisResponse>(`/projects/${proposalId}/costplanAnalysis`, body),
  getCostplanAnalysis: (proposalId: string) => requests.get<IProposalCostplanAnalysis[]>(`/projects/${proposalId}/costplanAnalysis`),
  editCostplanAnalysis: (proposalId: string, body: IProposalCostplanAnalysisEditBody[]) =>
    requests.put<IProposalCostplanAnalysisResponse>(`/projects/${proposalId}/costplanAnalysis`, body),

  addCollaborationAnalysis: (proposalId: string, body: IProposalCollaborationAnalysisBody[]) =>
    requests.post<IProposalCollaborationAnalysisResponse>(`/projects/${proposalId}/collaborationAnalysis`, body),
  getCollaborationAnalysis: (proposalId: string) => requests.get<IProposalCollaborationAnalysis[]>(`/projects/${proposalId}/collaborationAnalysis`),
  editCollaborationAnalysis: (proposalId: string, body: IProposalCollaborationAnalysisEditBody[]) =>
    requests.put<IProposalCollaborationAnalysisResponse>(`/projects/${proposalId}/collaborationAnalysis`, body),

  addEconomyAnalysis: (proposalId: string, body: IProposalEconomyAnalysisBody[]) =>
    requests.post<IProposalEconomyAnalysisResponse>(`/projects/${proposalId}/economyAnalysis`, body),
  getEconomyAnalysis: (proposalId: string) => requests.get<IProposalEconomyAnalysis[]>(`/projects/${proposalId}/economyAnalysis`),
  editEconomyAnalysis: (proposalId: string, body: IProposalEconomyAnalysisEditBody[]) =>
    requests.put<IProposalEconomyAnalysisResponse>(`/projects/${proposalId}/economyAnalysis`, body),

  addFeasibilityStudy: (proposalId: string, body: IProposalFeasibilityStudyBody[]) =>
    requests.post<IProposalFeasibilityStudyResponse>(`/projects/${proposalId}/feasibilityStudy`, body),
  getFeasibilityStudy: (proposalId: string) => requests.get<IProposalFeasibilityStudy[]>(`/projects/${proposalId}/feasibilityStudy`),
  editFeasibilityStudy: (proposalId: string, body: IProposalFeasibilityStudyEditBody[]) =>
    requests.put<IProposalFeasibilityStudyResponse>(`/projects/${proposalId}/feasibilityStudy`, body),

  addFID: (proposalId: string, body: IProposalFID[]) => requests.post<IProposalFIDResponse>(`/projects/${proposalId}/fid`, body),

  // addApproval: (proposalId: string, body: IProposalApprovalBody[]) => requests.post<IProposalApprovalResponse>(`/projects/${proposalId}/approval`, body),
  // editApproval: (proposalId: string, body: IProposalApprovalEditBody[]) => requests.put<IProposalApprovalResponse>(`/projects/${proposalId}/approval`, body),
  // getApproval_DEPRECATED: (proposalId: string) => requests.get<IProposalApproval[]>(`/projects/${proposalId}/approval`),

  addAttachment: (proposalId: string, body: any) => requests.post<IProposalAttachmentResponse>(`projects/${proposalId}/Attachment`, body),
  getAttachment: (proposalId: string) => requests.get<IProposalAttachment[]>(`Projects/${proposalId}/attachment`),
  editAttachment: (proposalId: string, body: any) => requests.put(`Projects/${proposalId}/attachment`, body),

  addKajianFs: (proposalId: string, body: any) => requests.post<IProposalKajianFSResponse>(`Projects/${proposalId}/proposalAnalysis`, body),
  getKajianFs: (proposalId: string) => requests.get<IProposalKajianFS>(`Projects/${proposalId}/proposalAnalysis`),
  editKajianFs: (proposalId: string, body: any) => requests.put(`Projects/${proposalId}/proposalAnalysis`, body),
  deleteKajianFs: (proposalId: string) => requests.del(`Projects/${proposalId}/proposalAnalysis`),

  addRisk: (proposalId: string, body: IProposalRiskBody[]) => requests.post(`/projects/${proposalId}/riskAnalysis`, body),
  getRisk: (proposalId: string) => requests.get<IProposalRisk[]>(`/projects/${proposalId}/riskAnalysis`),
  editRisk: (proposalId: string, body: IProposalRiskEditBody[]) => requests.put(`/projects/${proposalId}/riskAnalysis`, body),

  addLegal: (proposalId: string, body: IProposalLegalBody[]) => requests.post(`/projects/${proposalId}/legalAnalysis`, body),
  getLegal: (proposalId: string) => requests.get<IProposalLegal[]>(`/projects/${proposalId}/legalAnalysis`),
  editLegal: (proposalId: string, body: IProposalLegalEditBody[]) => requests.put(`/projects/${proposalId}/legalAnalysis`, body),

  addPermission: (proposalId: string, body: IProposalPermissionBody[]) => requests.post(`/projects/${proposalId}/permissionAnalysis`, body),
  getPermission: (proposalId: string) => requests.get<IProposalPermission[]>(`/projects/${proposalId}/permissionAnalysis`),
  editPermission: (proposalId: string, body: IProposalPermissionEditBody[]) => requests.put(`/projects/${proposalId}/permissionAnalysis`, body),

  addScopeOfWork: (proposalId: string, body: IProposalScopeOfWorkBody[]) => requests.post(`/projects/${proposalId}/psScopeofworks`, body),
  getScopeOfWork: (proposalId: string) => requests.get<IProposalScopeOfWork[]>(`/projects/${proposalId}/psScopeofworks`),
  editScopeOfWork: (proposalId: string, body: IProposalScopeOfWorkEditBody[]) => requests.put(`/projects/${proposalId}/psScopeofworks`, body),

  addPsBackgroundObjective: (proposalId: string, body: IProposalPsBackgroundObjectiveBody[]) =>
    requests.post(`/projects/${proposalId}/psIntroduction`, body),
  getPsBackgroundObjective: (proposalId: string) => requests.get<IProposalPsBackgroundObjective[]>(`/projects/${proposalId}/psIntroduction`),
  editPsBackgroundObjective: (proposalId: string, body: IProposalPsBackgroundObjectiveEditBody[]) =>
    requests.put(`/projects/${proposalId}/psIntroduction`, body),

  addBackgroundObjective: (proposalId: string, body: IProposalBackgroundObjectiveBody[]) =>
    requests.post(`/projects/${proposalId}/Introduction`, body),
  getBackgroundObjective: (proposalId: string) => requests.get<IProposalBackgroundObjective[]>(`/projects/${proposalId}/Introduction`),
  editBackgroundObjective: (proposalId: string, body: IProposalBackgroundObjectiveEditBody[]) =>
    requests.put(`/projects/${proposalId}/Introduction`, body),

  getTemplateRincianInvestasi: (proposalId: string) => requests.getFile(`/Projects/psTimelineTemplate/${proposalId}`),
  uploadTemplateRincianInvestasi: (proposalId: string, data: FormData) =>
    requests.postFile(`/Projects/psTimelineTemplate/import/${proposalId}`, data),

  addTimeline: (proposalId: string, body: IProposalTimelineBody[]) => requests.post(`/projects/${proposalId}/psTimeline`, body),
  getTimeline: <T>(proposalId: string) => requests.get<T>(`/projects/${proposalId}/psTimeline`),
  editTimeline: (proposalId: string, body: IProposalTimelineEditBody[]) => requests.put(`/projects/${proposalId}/psTimeline`, body),
  deleteTimeline: (proposalId: string, id: string) => requests.del(`/projects/${proposalId}/${id}/psTimeline`),

  addDrawdown: (proposalId: string, body: IProposalDrawdownBody[]) => requests.post(`/projects/${proposalId}/psdrawdown`, body),
  getDrawdown: (proposalId: string) => requests.get<IProposalDrawdown[]>(`/projects/${proposalId}/psdrawdown`),
  editDrawdown: (proposalId: string, body: IProposalDrawdownEditBody[]) => requests.put(`/projects/${proposalId}/psdrawdown`, body),

  addConstructor: (proposalId: string, body: IProposalConstructorBody[]) => requests.post(`/projects/${proposalId}/contractor`, body),
  getConstructor: (proposalId: string) => requests.get<IProposalConstructor[]>(`/projects/${proposalId}/contractor`),
  editConstructor: (proposalId: string, body: IProposalConstructorEditBody[]) => requests.put(`/projects/${proposalId}/contractor`, body),

  addPsRiskMitigation: (proposalId: string, body: IProposalPsRiskMitigationBody[]) => requests.post(`/projects/${proposalId}/psRiskMitigation`, body),
  getPsRiskMitigation: (proposalId: string) => requests.get<IProposalPsRiskMitigation[]>(`/projects/${proposalId}/psRiskMitigation`),
  editPsRiskMitigation: (proposalId: string, body: IProposalPsRiskMitigationEditBody[]) =>
    requests.put(`/projects/${proposalId}/psRiskMitigation`, body),

  addtechnicalAnalysis: (proposalId: string, body: IProposalTechnicalAnalysisBody[]) =>
    requests.post(`/projects/${proposalId}/technicAnalysis`, body),
  getTechnicalAnalysis: (proposalId: string) => requests.get<IProposalTechnicalAnalysis[]>(`/projects/${proposalId}/technicAnalysis`),
  editTechnicalAnalysis: (proposalId: string, body: IProposalTechnicalAnalysisEditBody[]) =>
    requests.put(`/projects/${proposalId}/technicAnalysis`, body),

  addMember: (body: IProposalMemberBody) => requests.post(`/proposalMembers`, body),
  getMember: (proposalId: string, params?: { search?: string }) =>
    requests.get<IProposalMember[]>(`/proposalMembers/`, { params: { proposalId, ...params } }),
  deleteMember: (memberId: string) => requests.del(`/proposalMembers/${memberId}`),

  cancelProject: (proposalId: string, body: any) => requests.put(`/proposal/cancelProject/${proposalId}`, body),
  promoteProposalToDecisionGate: (proposalId: string, body: any) => requests.put(`/proposal/decisionGate/${proposalId}`, body),
  promoteProposal: (proposalId: string, status: ProjectStatus) => requests.put(`/proposal/${proposalId}/${status}`),

  getReviewerRefid: (proposalId: string) => requests.get<IProposalReviewer[]>(`/refid/${proposalId}/reviewerApproval`),
  getReviewer: (proposalId: string) => requests.get<IProposalReviewer[]>(`/projects/${proposalId}/reviewerApproval`),

  getLog: (proposalId: string) => requests.get<IProposalLog[]>(`/projects/${proposalId}/logs`),
  getRefidLog: (proposalId: string) => requests.get<TProposalRefidHistory[]>(`/projects/${proposalId}/refidLogs`),

  getWBS: (proposalId: string, year: string) => requests.get<IWBS>(`/projects/${proposalId}/wbs/${year}`),

  getAttachmentByType: (proposalId: string, typeId: string) =>
    requests.get<IProposalAttachmentResponse[]>(`/projects/${proposalId}/attachment/${typeId}`),
  deleteAttachment: (proposalId: string, id: string) => requests.del(`/Projects/${proposalId}/${id}/attachment`),

  getTasks: (proposalId: string) => requests.get<ITask[]>(`projects/${proposalId}/tasks`),

  getSKTL: (proposalId: string) => requests.get<ISKTL[]>(`projects/${proposalId}/sktl`),

  getSCurveSPI: (proposalId: string) => requests.get<TSCurveSPI>(`/projects/${proposalId}/dashboard/scurve`),
  getSCurveCPI: (proposalId: string) => requests.get<TSCurveSPI>(`/projects/${proposalId}/dashboard/scurve/cpi`),
  getSPI: (proposalId: string) => requests.get<TSpi>(`/projects/${proposalId}/dashboard/spi`),
  getCPI: (proposalId: string) => requests.get<TSpi>(`/projects/${proposalId}/dashboard/cpi`),
};

const ReviewerLog = {
  getReviewerLog: (proposalId: string, typeId: string, proposalStatus: string, typeStatus?: string) =>
    requests.get<TReviewerLog[]>(
      `/projects/${proposalId}/reviewerLog/${typeId}${proposalStatus === ProjectStatus.Proposal ? "/-/" : `/${proposalStatus}`}${
        typeStatus ? `/${typeStatus}` : ""
      }`
    ),
  addReviewerLog: (proposalId: string, body: TReviewerLog) => requests.post(`/projects/${proposalId}/reviewerLog`, body),
  deleteReviewerLog: (proposalId: string, typeId: string, type?: string) => requests.del(`/projects/${proposalId}/reviewer/${typeId}/${type}`),
  editReviewerLog: (proposalId: string, body: TReviewerLogEditPayload) => requests.put(`/projects/${proposalId}/reviewerLog`, body),
  editReviewerAttachmentLog: (proposalId: string, body: TReviewerAttachmentLogPayload) =>
    requests.put(`/projects/${proposalId}/taskSubTaskAttachment`, body),
  reviewerAcceptance: (proposalId: string, body: TReviewerAttachmentLogPayload) =>
    requests.put(`/projects/${proposalId}/statusTaskSubTaskAttachment`, body),

  getComment: (proposalId: string) => requests.get<IComment[]>(`projects/${proposalId}/reviewerlog/REVIEWER`),
};

const FID = {
  getAmandementGrid: (taskId: string, subTaskId?: string) =>
    requests.get<TPhysicalPlan[]>(`/task/physicalPlan/${taskId}${subTaskId === "" ? "" : `/${subTaskId}`}`),
  addAmandement: (body: any) => requests.post(`/task/requestAmandemen`, body),

  getPhysicalPlan: (taskId: string, subTaskId?: string) =>
    requests.get<TPhysicalPlan[]>(`/task/physicalPlan/${taskId}${subTaskId === "" ? "" : `/${subTaskId}`}`),
  addPhysicalPlan: (body: TPhysicalPlanBody) => requests.post(`/task/physicalPlan`, body),
  editPhysicalPlan: (physicalPlanId: string, body: TPhysicalPlanBody) => requests.put(`/task/physicalPlan/${physicalPlanId}`, body),
  deletePhysicalPlan: (physicalPlanId: string) => requests.del(`/task/physicalPlan/${physicalPlanId}`),

  getFinancialPlan: (taskId: string, subTaskId?: string) =>
    requests.get<TFinancialPlan[]>(`/task/financialPlan/${taskId}${subTaskId === "" ? "" : `/${subTaskId}`}`),
  addFinancialPlan: (body: TFinancialPlanBody) => requests.post(`/task/financialPlan`, body),
  editFinancialPlan: (financialPlanId: string, body: TFinancialPlanBody) => requests.put(`/task/financialPlan/${financialPlanId}`, body),
  deleteFinancialPlan: (financialPlanId: string) => requests.del(`/task/financialPlan/${financialPlanId}`),

  getTotalMilestonestasks: (proposalId: string) => requests.get<TTotalMilestonestasks>(`/projects/${proposalId}/totalMilestonestasks`),

  addMilestones: (proposalId: string, body: IFidMilestoneBody) => requests.post(`/projects/${proposalId}/milestones`, body),
  deleteMilestones: (proposalId: string, milestoneId: string) => requests.del(`/projects/${proposalId}/milestones/${milestoneId}`),
  getMilestonesGrid: (proposalId: string, params: IFidMilestoneQueryParams) =>
    requests.get<DataGridResult<IFidMilestone[]>>(`/Projects/milestonestasks/grid`, { params: { proposalId, ...params } }),

  getTaskAttachmentSuperadmin: (tasksId: string) => requests.get<TTaskAttachmentSuperadmin[]>(`/projects/tasks/admin/${tasksId}`),
  addTaskSuperadmin: (body: any) => requests.post(`/projects/tasks/admin`, body),
  editTaskSuperadmin: (taskId: string, body: any) => requests.put(`/projects/tasks/admin/${taskId}`, body),

  addTask: (body: IFidMilestoneTaskBody) => requests.post(`/projects/tasks`, body),
  editTask: (taskId: string, body: IFidMilestoneTaskBody) => requests.put(`/projects/tasks/${taskId}`, body),
  deleteTask: (taskId: string) => requests.del(`/projects/tasks/${taskId}`),

  getTaskSubTaskGrid: (proposalId: string, params: IFidMilestoneQueryParams, organizationId?: string) =>
    requests.get<DataGridResult<IFidTask[]>>(`/Projects/tasksubtasks/grid`, { params: { proposalId, ...params, organizationId } }),
  addSubTask: (body: IFidMilestoneSubTaskBody) => requests.post(`/projects/subtasks`, body),
  editSubTask: (subtaskId: string, body: IFidMilestoneSubTaskBody) => requests.put(`/projects/subtasks/${subtaskId}`, body),
  deleteSubTask: (subtaskId: string) => requests.del(`/projects/subtasks/${subtaskId}`),

  getProgressRealization: (proposalId: string) => requests.get<IProgressRealization[]>(`/projects/${proposalId}/physicalActual`),
  addProgressRealization: (body: IProgressRealizationBody) => requests.post(`/task/physicalActual`, body),
  editProgressRealization: (phisicalActualId: string, body: IProgressRealizationBody) =>
    requests.put(`/task/physicalActual/${phisicalActualId}`, body),
  getProgressRealizationHistory: (phisicalActualId: string) =>
    requests.get<IProgressRealizationHistory[]>(`/task/physicalActual/${phisicalActualId}`),

  getNewProgressRealization: (proposalId: string, params: IFidMilestoneQueryParams, organizationId?: string) =>
    requests.get<DataGridResult<TNewProgressRealization[]>>(`/Projects/progressRealizations/grid`, {
      params: { proposalId, ...params, organizationId },
    }),

  getPhysicalActualProgress: (subtaskId: string, tasksId: string) =>
    requests.get<IProgressRealization[]>(`/task/physicalActual/${tasksId}/${subtaskId}`),
  deletePhysicalActualProgress: (id: string) => requests.del(`/task/physicalActual/${id}`),
  deleteFinancialActualProgress: (id: string) => requests.del(`/task/financialActual/${id}`),
  getFinancialActualProgress: (subtaskId: string, tasksId: string) =>
    requests.get<IProgressRealization[]>(`/task/financialActual/${tasksId}/${subtaskId}`),

  getNewCostRealization: (proposalId: string, params: IFidMilestoneQueryParams, organizationId?: string) =>
    requests.get<DataGridResult<TNewProgressRealization[]>>(`/Projects/costRealizations/grid`, { params: { proposalId, ...params, organizationId } }),

  getCostRealization: (proposalId: string) => requests.get<TCostRealization[]>(`/projects/${proposalId}/financialActual`),
  addCostRealization: (body: any) => requests.post(`/task/financialActual`, body),
  editCostRealization: (financialActualId: string, body: any) => requests.put(`/task/financialActual/${financialActualId}`, body),

  getTaskAttachment: (proposalId: string, taskId: string) =>
    requests.get<ITaskSubTaskAttachment[]>(`/projects/${proposalId}/taskSubTaskAttachment/${taskId}`),
  getSubtaskAttachment: (proposalId: string, taskId: string, subTaskId: string) =>
    requests.get<ITaskSubTaskAttachment[]>(`/projects/${proposalId}/taskSubTaskAttachment/${taskId}/${subTaskId}`),

  addTaskSubtaskAttachment: (proposalId: string, body: any) => requests.post(`projects/${proposalId}/taskSubTaskAttachment`, body),
  editTaskSubtaskAttachment: (proposalId: string, body: any) => requests.put(`projects/${proposalId}/taskSubTaskAttachment`, body),
  deleteTaskSubtaskAttachment: (proposalId: string, taskSubtaskAttachmentId: string) =>
    requests.del(`projects/${proposalId}/statusTaskSubTaskAttachment/${taskSubtaskAttachmentId}`),

  downloadTemplatePlan: (proposalId: string) => requests.getFile(`projects/exportPlan/${proposalId}`),
  uploadExcelPlan: (data: FormData, proposalId: string) => requests.putFile<any>(`/projects/importPlan/${proposalId}`, data),
  saveImportPlan: (proposalId: string, body: PreviewDataExcel[]) => requests.post(`projects/saveImportPlan/${proposalId}`, body),

  downloadTemplateMilestones: (proposalId: string) => requests.getFile(`projects/exportMilestones/${proposalId}`),
  uploadExcelMilestones: (data: FormData, proposalId: string) => requests.putFile<any>(`/projects/importMilestones/${proposalId}`, data),
  saveImportMilestones: (proposalId: string, body: PreviewDataExcel[]) => requests.post(`projects/saveImportMilestones/${proposalId}`, body),

  taskPlanning: (proposalId: string, body: PreviewDataExcel[]) => requests.post(`projects/taskPlanning/${proposalId}`, body),
};

const ReFID = {
  addTask: (body: IFidMilestoneTaskReFIDBody) => requests.post(`/refid/tasks`, body),
  editTask: (taskId: string, body: IFidMilestoneTaskReFIDBody) => requests.put(`/refid/tasks/${taskId}`, body),
  deleteTask: (taskId: string) => requests.del(`/refid/tasks/${taskId}`),

  getTaskSubTaskGrid: (proposalId: string, params: IFidMilestoneQueryParams, organizationId?: string) =>
    requests.get<DataGridResult<IFidTask[]>>(`/refid/tasksubtasks/grid`, { params: { proposalId, ...params, organizationId } }),
  addSubTask: (body: IFidMilestoneSubTaskBody) => requests.post(`/refid/subtasks`, body),
  editSubTask: (subtaskId: string, body: IFidMilestoneSubTaskBody) => requests.put(`/refid/subtasks/${subtaskId}`, body),
  deleteSubTask: (subtaskId: string) => requests.del(`/refid/subtasks/${subtaskId}`),

  submitReFid: (proposalId: string, body: any) => requests.put(`/projects/${proposalId}/requestRefid/submit`, body),
  approveReFid: (proposalId: string, status: string, body: any) => requests.put(`/projects/${proposalId}/requestRefid/${status}`, body),

  getGrid: () => requests.get<DataGridResult<IRefid[]>>(`projects/requestRefid/grid`),
  getCapexReFid: (proposalId: string, params: IFidMilestoneQueryParams) =>
    requests.get<DataGridResult<IFidMilestone[]>>(`/refid/milestonestasksRefid/grid`, { params: { proposalId, ...params } }),
  getgeneralInformationReFid: (proposalId: string) => requests.get<IGeneralReFid>(`/refid/${proposalId}/requestRefid`),
  addgeneralInformationReFid: (proposalId: string, body: IGeneralReFid) => requests.post(`/projects/${proposalId}/requestRefid`, body),
  editgeneralInformationReFid: (proposalId: string, body: IGeneralReFid, generalInformationReFid: string) =>
    requests.put(`/projects/${proposalId}/requestRefid/${generalInformationReFid}`, body),
  deleteGeneralInformationReFid: (proposalId: string, generalInformationReFid: string) =>
    requests.del(`/projects/${proposalId}/requestRefid/${generalInformationReFid}`),
};

const General = {
  getStepBarPercentage: (proposalId: string) => requests.get<TStepBarPercentage>(`/projects/${proposalId}/stepbarPercentage`),

  getProjectCategory: () => requests.get<IProjectCategory[]>("/projects/category"),

  getProjectCategoryAll: () => requests.get<TProjectCategoryAll[]>("/projectCategory"),
  addProjectCategoryAll: (body: TProjectCategoryAllBody) => requests.post("/projectCategory", body),
  editProjectCategoryAll: (id: string, body: TProjectCategoryAllBody) => requests.put(`/projectCategory/${id}`, body),
  deleteProjectCategoryAll: (id: string) => requests.del(`/projectCategory/${id}`),

  getProjectType: (category?: string) => requests.get<IProjectType[]>("/projectType", { params: { projectCategoryId: category } }),
  getProjectTypeByUnitBusiness: (category?: string) =>
    requests.get<IProjectType[]>("/UnitBusinessCategory/project", { params: { projectCategoryId: category } }),
  addProjectType: (body: IProjectTypeBody) => requests.post("/projectType", body),
  editProjectType: (projectTypeId: string, body: IProjectTypeBody) => requests.put(`/projectType/${projectTypeId}`, body),
  deleteProjectType: (projectTypeId: string) => requests.del(`/projectType/${projectTypeId}`),

  getRegional: () => requests.get<IRegional[]>("/regional"),
  addRegional: (body: IRegionalBody) => requests.post("/regional", body),
  editRegional: (id: string, body: IRegionalBody) => requests.put(`/regional/${id}`, body),
  deleteRegional: (id: string) => requests.del(`/regional/${id}`),

  getUnitBusiness: (params?: { categoryUnitBusinessId: string | null; category?: string }) =>
    requests.get<IUnitBusiness[]>("/unitBusiness", { params }),
  getUnitBusinessGrid: (params: IUnitBusinessQueryParams) => requests.get<DataGridResult<IUnitBusinessGrid[]>>("/unitBusiness/grid", { params }),
  addUnitBusiness: (body: IUnitBusinessBody) => requests.post("/unitBusiness", body),
  editUnitBusiness: (id: string, body: IUnitBusinessBody) => requests.put(`/unitBusiness/${id}`, body),
  deleteUnitBusiness: (id: string) => requests.del(`/unitBusiness/${id}`),

  getUnitBusinessCategory: () => requests.get<IUnitBusinessCategory[]>("/unitBusinessCategory"),
  getUnitBusinessCategoryGrid: (params: IUnitBusinessQueryParams) =>
    requests.get<DataGridResult<IUnitBusinessCategoryGrid[]>>("/unitBusinessCategory/grid", { params }),
  addUnitBusinessCategory: (body: IUnitBusinessCategoryBody) => requests.post("/unitBusinessCategory", body),
  editUnitBusinessCategory: (id: string, body: IUnitBusinessCategoryBody) => requests.put(`/unitBusinessCategory/${id}`, body),
  deleteUnitBusinessCategory: (id: string) => requests.del(`/unitBusiness/${id}`),

  getSubHoldingsGrid: (params: ISubHoldingQueryParams) => requests.get<DataGridResult<ISubHoldingGrid[]>>("/subHoldings/grid", { params }),
  getSubHoldings: () => requests.get<ISubHolding[]>("/subHoldings"),
  addSubHoldings: (body: ISubHoldingBody) => requests.post("/subHoldings", body),
  editSubHoldings: (id: string, body: ISubHoldingBody) => requests.put(`${"/subHoldings"}/${id}`, body),
  deleteSubHoldings: (id: string) => requests.del(`${"/subHoldings"}/${id}`),

  getFile: (proposalId: string, fileName: string) => requests.get<IFile>(`File/${proposalId}/${fileName}`),
  getFileWithDifferentBehaviour: (proposalId: string, fileName: string) => requests.getFile(`File/another/${proposalId}/${fileName}`),
  getFileWithDifferentResponse: (proposalId: string, fileName: string) => requests.get<any>(`File/${proposalId}/${fileName}`),
  getNotification: () => requests.get<INotification>("projects/notification"),
};

const Master = {
  // getProjectCategory: (params: IProjectCategoryQueryParams) => requests.get<DataGridResult<IProjectCategory[]>>(`/projectCategories/grid`, { params }),
  // addProjectCategory: (body: IProjectCategoryBody) => requests.post(`/projectCategories`, body),
  // editProjectCategory: (id: string, body: IProjectCategoryBody) => requests.put(`/projectCategories/${id}`, body),
  // deleteProjectCategory: (id: string) => requests.del(`/projectCategories/${id}`),

  getProjectCategoryNBDGrid: (params?: any) => requests.get<DataGridResult<IProjectCategoryNBD[]>>(`/projectCategoryNbd/grid`, { params }),
  getProjectCategoryNBD: () => requests.get<IProjectCategoryNBD[]>(`/projectCategoryNbd`),
  addProjectCategoryNBD: (body: any) => requests.post(`/projectCategoryNbd`, body),
  editProjectCategoryNBD: (id: string, body: any) => requests.put(`/projectCategoryNbd/${id}`, body),
  deleteProjectCategoryNBD: (id: string) => requests.del(`/projectCategoryNbd/${id}`),

  getUnderlyingGrid: (params: IUnderlyingQueryParams) => requests.get<DataGridResult<IUnderlying[]>>(`/ProjectUnderLyings/grid`, { params }),
  getUnderlying: () => requests.get<IUnderlying[]>(`/ProjectUnderLyings`),
  addUnderlying: (body: IUnderlyingBody) => requests.post(`/ProjectUnderLyings`, body),
  editUnderlying: (id: string, body: IUnderlyingBody) => requests.put(`/ProjectUnderLyings/${id}`, body),
  deleteUnderlying: (id: string) => requests.del(`/ProjectUnderLyings/${id}`),

  getAssetCategoryGrid: (params: IAssetCategoryQueryParams) => requests.get<DataGridResult<IAssetCategory[]>>(`/assetCategories/grid`, { params }),
  getAssetCategory: () => requests.get<IAssetCategory[]>(`/assetCategories`),
  addAssetCategory: (body: IAssetCategoryBody) => requests.post(`/assetCategories`, body),
  editAssetCategory: (id: string, body: IAssetCategoryBody) => requests.put(`/assetCategories/${id}`, body),
  deleteAssetCategory: (id: string) => requests.del(`/assetCategories/${id}`),

  getInventoryCategoryGrid: (params: IInventoryCategoryQueryParams) =>
    requests.get<DataGridResult<IInventoryCategory[]>>(`/inventoryCategories/grid`, { params }),
  getInventoryCategory: () => requests.get<IInventoryCategory[]>(`/inventoryCategories`),
  addInventoryCategory: (body: IInventoryCategoryBody) => requests.post(`/inventoryCategories`, body),
  editInventoryCategory: (id: string, body: IInventoryCategoryBody) => requests.put(`/inventoryCategories/${id}`, body),
  deleteInventoryCategory: (id: string) => requests.del(`/inventoryCategories/${id}`),

  getOwnershipCategoryGrid: (params: IOwnershipCategoryQueryParams) =>
    requests.get<DataGridResult<IOwnershipCategory[]>>(`/ownershipStatus/grid`, { params }),
  addOwnershipCategory: (body: IOwnershipCategoryBody) => requests.post(`/ownershipStatus`, body),
  editOwnershipCategory: (id: string, body: IOwnershipCategoryBody) => requests.put(`/ownershipStatus/${id}`, body),
  deleteOwnershipCategory: (id: string) => requests.del(`/ownershipStatus/${id}`),

  getReviewerByOrganization: (organizationId: string) => requests.get<TOrganization[]>(`/organizations/reviewer/${organizationId}`),
  getOrganizations: () => requests.get<TOrganization[]>("/organizations"),
  getOrganizationGrid: (params: TOrganizationQueryParams) => requests.get<DataGridResult<TOrganization[]>>("/organizations/grid", { params }),
  addOrganization: (body: TSelectedOrganization) => requests.post("/organizations", body),
  editOrganization: (id: string, body: TSelectedOrganization) => requests.put(`/organizations/${id}`, body),
  deleteOrganization: (id: string) => requests.del(`/organizations/${id}`),

  getSettingApprovalGrid: (params: TSettingApprovalQueryParams) =>
    requests.get<DataGridResult<TSettingApproval[]>>("/settingApprovals/grid", { params }),
  addSettingApproval: (body: TSelectedSettingApproval) => requests.post("/settingApprovals", body),
  editSettingApproval: (id: string, body: TSelectedSettingApproval) => requests.put(`/settingApprovals/${id}`, body),
  deleteSettingApproval: (id: string) => requests.del(`/settingApprovals/${id}`),
  getOwnershipStatus: () => requests.get<IOwnershipCategory[]>(`/ownershipStatus`),

  getGeneralLogGrid: (params: TGeneralLogQueryParams) => requests.get<DataGridResult<TGeneralLog[]>>("projects/generalLogs/grid", { params }),

  getAuctionStatus: () => requests.get<TAuctionStatus[]>("/auctionStatus"),
  getAuctionStatusGrid: (params: TAuctionStatusQueryParams) => requests.get<DataGridResult<TAuctionStatus[]>>("/auctionStatus/grid", { params }),
  addAuctionStatus: (body: TSelectedAuctionStatus) => requests.post("/auctionStatus", body),
  editAuctionStatus: (id: string, body: TSelectedAuctionStatus) => requests.put(`/auctionStatus/${id}`, body),
  deleteAuctionStatus: (id: string) => requests.del(`/auctionStatus/${id}`),

  getPeruntukan: () => requests.get<TPeruntukan[]>("/landStatusAllotment"),
  getPeruntukanGrid: (params: TPeruntukanQueryParams) => requests.get<DataGridResult<TPeruntukanGrid[]>>("/landStatusAllotment/grid", { params }),
  addPeruntukan: (body: TPeruntukanBody) => requests.post("/landStatusAllotment", body),
  editPeruntukan: (id: string, body: TPeruntukanBody) => requests.put(`/landStatusAllotment/${id}`, body),
  deletePeruntukan: (id: string) => requests.del(`/landStatusAllotment/${id}`),
};

const Amandement = {
  approveRejectAmandement: (status: string, body: TApproveRejectAmandementBody) => requests.put(`/task/requestAmandemen/request/${status}`, body),
  getAmandementGrid: (params: TAmandementQueryParams, proposalId: string) =>
    requests.get<DataGridResult<TAmandement[]>>("/task/requestAmandemen/grid", { params: { ...params, proposalId: proposalId } }),
  editAmandement: (id: string, body: any) => requests.put(`/task/requestAmandemen/${id}`, body),
  deleteAmandement: (id: string) => requests.del(`/task/requestAmandemen/${id}`),
};

const Consolidation = {
  getConsolidation: (proposalId: string) => requests.get<TConsolidation[]>(`/projects/${proposalId}/consolidation`),
  getCostConsolidation: (proposalId: string) => requests.get<TConsolidationCost[]>(`/projects/${proposalId}/consolidationCost`),
};

const ABI = {
  getABIGrid: (proposalId: string, year: number) => requests.get<AbiGridResponse[]>("/abi", { params: { proposalId: proposalId, year: year } }),
  lockAbi: (payload: AbiLockPayload) => requests.post<void>(`/abi/submit`, payload),
  unLockAbi: (payload: AbiLockPayload) => requests.post<void>(`/abi/unlock`, payload),
  getTotalABI: (proposalId: string, year: string) => requests.get<TTotalAbiResponse>(`/Abi/${proposalId}/${year}`),
};

const UploadProjectAdmin = {
  getTemplateUploadProjectAdmin: (payload: TDownloadTemplateProjectAdminPayload) => requests.getFileWithPost(`projects/planAdmin`, payload),
  uploadTemplateUploadProjectAdmin: (payload: FormData) => requests.postFile<TUploadTemplateResponse>(`projects/planAdmin/import`, payload),
  submitTemplateUploadProjectAdmin: (payload: any) => requests.post<void>(`projects/planAdmin/submit`, payload),
};

const Dashboard = {
  getPieOverdue: (params?: any) => requests.get<IPieOverdue>("/dashboard/pie/overdue", { params }),
  getPieStatus: () => requests.get<IPieStatus>("/dashboard/pieStatus"),
  getProjectStatus: (params?: any) => requests.get<TProjectStatus>("/dashboard/project/status", { params }),
  getPieCategory: (params?: any) => requests.get<IPieCategory>("/dashboard/pie/category", { params }),
  getLineProjectCost: () => requests.get<ILineProjectCost>("/dashboard/line/cost"),
  getLineProjectCount: (params?: any) => requests.get<ILineProjectCount>("/dashboard/line/count", { params }),
  getBarRealization: (params?: any) => requests.get<IBarRealization>("/dashboard/bar/planRealization", { params }),
  getBarProjectSummary: (params?: any) => requests.get<IBarRealization>("/dashboard/bar/projectSummary", { params }),
  getDashboardBarRealizationPlanDetail: (identifier: string) => requests.get<IBarRealization>(`/dashboard/bar/${identifier}`),
  getRecentProject: (params?: any) => requests.get<IRecentProject[]>("/dashboard/project/recent", { params }),
  // Projects
  getStatusTask: (payload: TStatusProjectPayload) => requests.get<TStatusProjectResponse>("/dashboard/task", { params: payload }),
  getProjectsList: (payload: TProjectsListPayload) =>
    requests.get<TProjectsListResponse[]>("/projects", {
      params: {
        organizationId: payload.organizationId || NO_SUBTASK_ID,
        year: payload.year,
        projectCategory: payload.projectCategory || NO_SUBTASK_ID,
        unitBusinessId: payload.unitBusinessId,
      },
    }),

  getSCurveSPI: (payload: TStatusProjectPayload) => requests.get<TSCurveSPI>(`/dashboard/scurve`, { params: payload }),
  getProjectBudgetTask: (payload: TStatusProjectPayload) => requests.get<IBarRealization>("/dashboard/bar/task/summary", { params: payload }),
  getBarMilestones: (payload: TStatusProjectPayload, type: string) =>
    requests.get<IBarRealization>(`/dashboard/bar/milestones/${type}`, { params: payload }),
  getSCurveTask: (proposalId: any, taskId: string) => requests.post<TSCurveSPI>(`/projects/${proposalId}/task/scurve`, [taskId]),
  // getProjectBudgetDetailTask: (proposalId: string, isPlan: boolean) =>
  //   requests.get<IBarRealization>(`dashboard/bar/task/${proposalId}/detail`, { params: { isPlan: isPlan } }),
  getProjectBudgetDetailTask: (payload: TStatusProjectPayload, isPlan: boolean) =>
    requests.get<IBarRealization>(`dashboard/bar/task/detail/${isPlan}`, { params: payload }),
};

const agent = {
  Account,
  User,
  Role,
  Example,
  Land,
  General,
  ProjectManagement,
  Proposal,
  Master,
  ReFID,
  FID,
  Organization,
  ChangeRequest,
  ReviewerLog,
  ABI,
  Consolidation,
  Dashboard,
  Amandement,
  UploadProjectAdmin,
};

export default agent;
