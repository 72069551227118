import React, { useEffect, useState } from "react";
import { GridRowHeightParams } from "@mui/x-data-grid-premium";
import { StyledDataGridPremium } from "../../../../../../app/component/StyledDataGridPremium";
import useQuery from "../../../../../../app/hook/useQuery";
import { ISKTL } from "../../../../../../app/models/sktl";
import agent from "../../../../../../app/api/agent";
import { Box, Divider } from "@mui/material";
import { NO_SUBTASK_ID } from "../../../../../../app/config/enum";
import { observer } from "mobx-react-lite";

type TSKTLDataGridProps = {
  proposalIdFromProjectDashboard?: string;
};

function SKTLDataGrid({ proposalIdFromProjectDashboard }: TSKTLDataGridProps) {
  const [sktl, setSktl] = useState<ISKTL[]>([]);
  const query = useQuery();
  const id = query.get("id");

  console.log("proposalIdFromProjectDashboard", proposalIdFromProjectDashboard);

  useEffect(() => {
    if (!id && !proposalIdFromProjectDashboard) return;
    let tempId = id ?? proposalIdFromProjectDashboard;

    agent.Proposal.getSKTL(tempId ?? "").then((res) => {
      setSktl(res);
    });
  }, []);

  return (
    <StyledDataGridPremium
      getRowId={(row) => row.taskSubTaskName + row.taskId + Math.random()}
      getRowHeight={({ id, densityFactor, model }: GridRowHeightParams) => {
        console.log("model", model);
        if (model.years.length) {
          return 60 * model.years.length;
        }
        return null;
      }}
      showCellVerticalBorder
      disableRowSelectionOnClick
      autoHeight
      columns={[
        {
          field: "taskSubTaskName",
          disableColumnMenu: true,
          headerAlign: "center",
          headerName: "Name",
          width: 250,
          flex: 1,
          renderCell: (params) => (
            <Box
              sx={{
                fontWeight: params.row.subTaskId === NO_SUBTASK_ID ? "bold" : "",
                color: params.row.taskId !== NO_SUBTASK_ID ? "#494949" : params.row.subTaskId !== NO_SUBTASK_ID ? "" : "black",
                width: "150px",
                paddingLeft: params.row.taskId !== NO_SUBTASK_ID ? "24px" : params.row.subTaskId !== NO_SUBTASK_ID ? "48px" : "",
              }}
            >
              {params.row.taskSubTaskName}
            </Box>
          ),
        },
        {
          field: "months",
          headerAlign: "center",
          headerName: "Month/Year",
          disableColumnMenu: true,
          width: 150,
          renderCell: (params) => {
            let temp: any[] = [];
            params.row.years.map((year: any) => year.months.map((month: any) => temp.push({ ...month, month: `${month.month} ${year.year}` })));

            return (
              <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                {temp.map((item: any, index: number) => (
                  <Box sx={{ width: "100%", textAlign: "center" }}>
                    <Box>{item.month}</Box>
                    {index + 1 === temp.length ? "" : <Divider sx={{ my: "6px" }} />}
                  </Box>
                ))}
              </Box>
            );
          },
        },
        {
          field: "constraint",
          headerAlign: "center",
          headerName: "Constraint",
          disableColumnMenu: true,
          width: 250,
          renderCell: (params) => {
            let temp: any[] = [];
            params.row.years.map((year: any) => year.months.map((month: any) => temp.push(month)));

            return (
              <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                {temp.map((item: any, index: number) => (
                  <Box sx={{ width: "100%", textAlign: "center" }}>
                    <Box>{item.constraint || "-"}</Box>
                    {index + 1 === temp.length ? "" : <Divider sx={{ my: "6px" }} />}
                  </Box>
                ))}
              </Box>
            );
          },
        },
        {
          field: "mitigation",
          headerName: "Mitigation",
          headerAlign: "center",
          disableColumnMenu: true,
          width: 250,
          renderCell: (params) => {
            let temp: any[] = [];
            params.row.years.map((year: any) => year.months.map((month: any) => temp.push(month)));

            return (
              <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                {temp.map((item: any, index: number) => (
                  <Box sx={{ width: "100%", textAlign: "center" }}>
                    <Box>{item.mitigation || "-"}</Box>
                    {index + 1 === temp.length ? "" : <Divider sx={{ my: "6px" }} />}
                  </Box>
                ))}
              </Box>
            );
          },
        },
      ]}
      rows={sktl ?? []}
    />
  );
}

export default observer(SKTLDataGrid);
