import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, CircularProgress, Collapse, Divider, List, ListItemButton, ListItemIcon, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ProposalDataGrid from "./ProposalDataGrid";
import { useStore } from "../../../../app/stores/store";
import EmptyState from "../../../../app/component/EmptyState";
import { observer } from "mobx-react-lite";
import { TProposalIndexPageProps } from "../ProposalIndexPage";

function CollapsableDataList({ projectCategory, projectCategoryId }: TProposalIndexPageProps) {
  const [isOpenId, setIsOpenId] = useState<string>();
  const { getProjectType, getProjectTypesByUnitBusiness, projectTypesByUnitBusiness, projectTypesByUnitBusinessLoading } =
    useStore().projectTypeStore;

  useEffect(() => {
    getProjectTypesByUnitBusiness(projectCategoryId);
    getProjectType(projectCategoryId);
  }, [getProjectType, getProjectTypesByUnitBusiness, projectCategoryId]);

  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }} component="nav" aria-labelledby="nested-list-subheader">
      {projectTypesByUnitBusinessLoading ? (
        <Box display="flex" justifyContent="center" py={2}>
          <CircularProgress />
        </Box>
      ) : projectTypesByUnitBusiness.length > 0 ? (
        projectTypesByUnitBusiness.map((i) => (
          <>
            <ListItemButton
              onClick={() => setIsOpenId((prev) => (prev === i.id ? undefined : i.id))}
              sx={{
                py: 2,
                borderRadius: 2,
              }}
            >
              <ListItemIcon>{isOpenId === i.id ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
              <Typography color="black" fontWeight={700}>
                {i.name}
              </Typography>
              {!!i.projectCount && (
                <Box
                  minWidth={"40px"}
                  height={"40px"}
                  ml={2}
                  sx={{
                    backgroundColor: ({ palette }) => palette.primary.light,
                    borderRadius: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 2,
                  }}
                >
                  <Typography fontWeight={700} color={({ palette }) => palette.primary.main}>
                    {i.projectCount}
                  </Typography>
                </Box>
              )}
            </ListItemButton>
            <Divider />
            <Collapse in={isOpenId === i.id} timeout="auto" unmountOnExit>
              <ProposalDataGrid projectCategory={projectCategory} projectCategoryId={projectCategoryId} projectType={i.id} />{" "}
              {/* add project type id */}
            </Collapse>
          </>
        ))
      ) : (
        <EmptyState />
      )}
    </List>
  );
}

export default observer(CollapsableDataList);
