import { makeAutoObservable, runInAction, toJS } from "mobx";
import agent from "../api/agent";
import {
  IProposalGeneralInformation,
  IProposalGeneralInformationBody,
  IProposalGeneralInformationEditBody,
} from "../models/proposalGeneralInformation";
import { IProposalLand, IProposalLandBody, IProposalLandEditBody } from "../models/proposalLand";
import { IProposalProductAnalysis, IProposalProductAnalysisBody, IProposalProductAnalysisEditBody } from "../models/proposalProductAnalysis";
import { IProposalMarketAnalysis, IProposalMarketAnalysisBody, IProposalMarketAnalysisEditBody } from "../models/proposalMarketAnalysis";
import { IProposalCostplanAnalysis, IProposalCostplanAnalysisBody, IProposalCostplanAnalysisEditBody } from "../models/proposalCostplanAnalysis";
import {
  IProposalCollaborationAnalysis,
  IProposalCollaborationAnalysisBody,
  IProposalCollaborationAnalysisEditBody,
} from "../models/proposalCollaborationAnalysis";
import { IProposalEconomyAnalysis, IProposalEconomyAnalysisBody, IProposalEconomyAnalysisEditBody } from "../models/proposalEconomyAnalysis";
import { IProposalFeasibilityStudy, IProposalFeasibilityStudyBody, IProposalFeasibilityStudyEditBody } from "../models/proposalFeasibilityStudy";
import { IProposalFID } from "../models/proposalFID";
import { IProposalAttachment, IProposalAttachmentResponse } from "../models/proposalAttachment";
import { IProposalRisk, IProposalRiskBody, IProposalRiskEditBody } from "../models/proposalProposalRisk";
import { IProposalLegal, IProposalLegalBody, IProposalLegalEditBody } from "../models/proposalProposalLegal";
import { IProposalPermission, IProposalPermissionBody, IProposalPermissionEditBody } from "../models/proposalPermission";
import { IProposalScopeOfWork, IProposalScopeOfWorkBody, IProposalScopeOfWorkEditBody } from "../models/proposalScopeOfWork";
import {
  IProposalBackgroundObjective,
  IProposalBackgroundObjectiveBody,
  IProposalBackgroundObjectiveEditBody,
} from "../models/proposalBackgroundObjective";
import { IProposalTimeline, IProposalTimelineBody, IProposalTimelineEditBody } from "../models/proposalTimeline";
import { IProposalCostBenefit, IProposalCostBenefitEditBody } from "../models/proposalCostBenefit";
import { IProposalInvestmentBudget, IProposalInvestmentBudgetEditBody } from "../models/proposalInvestmentBudget";
import { IProposalDrawdown, IProposalDrawdownBody, IProposalDrawdownEditBody } from "../models/proposalDrawdown";
import { IProposalConstructor, IProposalConstructorBody, IProposalConstructorEditBody } from "../models/proposalConstructor";
import { IProposalPsRiskMitigation, IProposalPsRiskMitigationBody, IProposalPsRiskMitigationEditBody } from "../models/proposalPsRiskMitigation";
import { IProposalTechnicalAnalysis, IProposalTechnicalAnalysisBody, IProposalTechnicalAnalysisEditBody } from "../models/proposalTechnicalAnalysis";
import { TPreparation, TProposalPreparationBody } from "../models/proposalPreparation";
import { TPreparationPermission, TProposalPreparationPermissionBody } from "../models/proposalPreparationPermission";
import { TConceptSketch, TProposalConceptSketchBody } from "../models/proposalConceptSketch";
import { TProposalSchematicDrawingBody, TSchematicDrawing } from "../models/proposalSchematicDrawing";
import { TDesign, TDesignBody, TDesignResponse } from "../models/proposalDesgin";
import { IWBS } from "../models/wbs";
import {
  IProposalPsBackgroundObjective,
  IProposalPsBackgroundObjectiveBody,
  IProposalPsBackgroundObjectiveEditBody,
} from "../models/proposalPsBackgroundObjective";
import { ITask } from "../models/task";
import { IProposalKajianFS } from "../models/proposalKajianFs";
import { TSCurveSPI, TSpi } from "../models/spi";

export class ProposalStore {
  generalInformation: IProposalGeneralInformation | null = null;
  generalInformationLoading: boolean = false;
  editGeneralInformationLoading = false;

  land: IProposalLand[] | null = null;
  landLoading = false;

  preparation: TPreparation[] | null = null;
  preparationLoading = false;

  preparationPermission: TPreparationPermission[] | null = null;
  preparationPermissionLoading = false;

  conceptSketch: TConceptSketch[] | null = null;
  conceptSketchLoading = false;

  schematicDrawing: TSchematicDrawing[] | null = null;
  schematicDrawingLoading = false;

  design: TDesignResponse[] | null = null;
  designLoading = false;

  productAnalysis: IProposalProductAnalysis[] | null = null;
  productAnalisysLoading: boolean = false;

  marketAnalysis: IProposalMarketAnalysis[] | null = null;
  marketAnalysisLoading = false;

  costplanAnalysis: IProposalCostplanAnalysis[] | null = null;
  costplanAnalysisLoading = false;

  collaborationAnalysis: IProposalCollaborationAnalysis[] | null = null;
  collaborationAnalysisLoading = false;

  economyAnalysis: IProposalEconomyAnalysis[] | null = null;
  economyAnalysisLoading = false;

  feasibilityStudy: IProposalFeasibilityStudy[] | null = null;
  feasibilityStudyLoading = false;

  risk: IProposalRisk[] | null = null;
  riskLoading = false;

  legal: IProposalLegal[] | null = null;
  legalLoading = false;

  permission: IProposalPermission[] | null = null;
  permissionLoading = false;

  scopeOfWork: IProposalScopeOfWork[] | null = null;
  scopeOfWorkLoading = false;

  psBackgroundObjective: IProposalPsBackgroundObjective[] | null = null;
  psBackgroundObjectiveLoading = false;

  backgroundObjective: IProposalBackgroundObjective[] | null = null;
  backgroundObjectiveLoading = false;

  timeline: IProposalTimeline[] | null = null;
  timelineLoading = false;

  investmentBudget: IProposalInvestmentBudget[] | null = null;
  investmentBudgetLoading = false;
  deleteInvestmentBudgetLoading = false;

  costBenefit: IProposalCostBenefit[] | null = null;
  costBenefitLoading = false;

  drawdown: IProposalDrawdown[] | null = null;
  drawdownLoading = false;

  constructors: IProposalConstructor[] | null = null;
  constructorsLoading = false;

  psRiskMitigation: IProposalPsRiskMitigation[] | null = null;
  psRiskMitigationLoading = false;

  technicalAnalysis: IProposalTechnicalAnalysis[] | null = null;
  technicalAnalysisLoading = false;

  attachment: IProposalAttachment[] | null = null;
  attachmentLoading = false;

  kajianFs: IProposalKajianFS | null = null;
  kajianFsLoading = false;

  wbs: IWBS | undefined = undefined;
  wbsLoading = false;

  deleteAttachmentLoading = false;

  task: ITask[] = [];
  taskLoading = false;

  spi: TSpi | null = null;
  spiLoading = false;

  sCurveSpi: TSCurveSPI | null = null;
  sCurveSpiLoading = false;

  sCurveCpi: TSCurveSPI | null = null;
  sCurveCpiLoading = false;

  cpi: TSpi | null = null;
  cpiLoading = false;

  loadingTemplateRincianInvestasi = false;

  constructor() {
    makeAutoObservable(this);
  }

  downloadTemplateRincianInvestasi = async (proposalId: string) => {
    this.loadingTemplateRincianInvestasi = true;

    try {
      await agent.Proposal.getTemplateRincianInvestasi(proposalId);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.loadingTemplateRincianInvestasi = false));
    }
  };

  uploadTemplateRincianInvestasi = async (proposalId: string, data: FormData) => {
    this.loadingTemplateRincianInvestasi = true;

    try {
      await agent.Proposal.uploadTemplateRincianInvestasi(proposalId, data);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.loadingTemplateRincianInvestasi = false));
    }
  };

  getSCurveCPI = async (proposalId: string) => {
    this.sCurveCpiLoading = true;

    try {
      const res = await agent.Proposal.getSCurveCPI(proposalId);

      runInAction(() => (this.sCurveCpi = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.sCurveCpiLoading = false));
    }
  };

  getSCurveSPI = async (proposalId: string) => {
    this.sCurveSpiLoading = true;

    try {
      const res = await agent.Proposal.getSCurveSPI(proposalId);

      runInAction(() => (this.sCurveSpi = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.sCurveSpiLoading = false));
    }
  };

  getSPI = async (proposalId: string) => {
    this.spiLoading = true;

    try {
      const res = await agent.Proposal.getSPI(proposalId);

      runInAction(() => (this.spi = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.spiLoading = false));
    }
  };

  getCPI = async (proposalId: string) => {
    this.cpiLoading = true;

    try {
      const res = await agent.Proposal.getCPI(proposalId);

      runInAction(() => (this.cpi = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.cpiLoading = false));
    }
  };

  addGeneralInformation = async (body: IProposalGeneralInformationBody) => {
    try {
      const res = await agent.Proposal.addGeneralInformation(body);
      return res;
    } catch (e) {
      throw e;
    }
  };

  getGeneralInformation = async (proposalId: string) => {
    this.generalInformationLoading = true;
    try {
      const res = await agent.Proposal.getGeneralInformation(proposalId);

      runInAction(() => (this.generalInformation = res));
      return res;
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.generalInformationLoading = false));
    }
  };
  resetGeneralInformation = async () => {
    this.generalInformation = null;
  };
  editGeneralInformation = async (proposalId: string, body: Partial<IProposalGeneralInformationEditBody>) => {
    this.editGeneralInformationLoading = true;
    try {
      return await agent.Proposal.editGeneralInformation(proposalId, body);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.editGeneralInformationLoading = false));
    }
  };

  addLand = async (proposalId: string, body: IProposalLandBody) => {
    try {
      return await agent.Proposal.addLand(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  editLand = async (proposalId: string, body: IProposalLandEditBody) => {
    try {
      return await agent.Proposal.editLand(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getLand = async (proposalId: string) => {
    this.landLoading = true;
    try {
      const res = await agent.Proposal.getLand(proposalId);

      runInAction(() => (this.land = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.landLoading = false));
    }
  };
  resetLand = () => {
    this.land = null;
  };

  // CONCEPT SKETCH CRUD
  addConceptSketch = async (proposalId: string, body: TProposalConceptSketchBody[]) => {
    try {
      return await agent.Proposal.addConceptSketch(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  getConceptSketch = async (proposalId: string) => {
    this.conceptSketchLoading = true;
    try {
      const res = await agent.Proposal.getConceptSketch(proposalId);

      runInAction(() => (this.conceptSketch = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.conceptSketchLoading = false));
    }
  };

  editConceptSketch = async (proposalId: string, body: TProposalConceptSketchBody[]) => {
    try {
      return await agent.Proposal.editConceptSketch(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  resetConceptSketch = () => {
    this.conceptSketch = null;
  };

  // DESIGN CRUD
  addDesign = async (proposalId: string, body: TDesignBody[]) => {
    try {
      return await agent.Proposal.addDesign(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  getDesign = async (proposalId: string) => {
    this.designLoading = true;
    try {
      const res = await agent.Proposal.getDesign(proposalId);

      runInAction(() => (this.design = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.designLoading = false));
    }
  };

  editDesign = async (proposalId: string, body: TDesignBody[]) => {
    try {
      return await agent.Proposal.editDesign(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  resetDesign = () => {
    this.design = null;
  };

  // SCHEMACTI DRAWING CRUD
  addSchematicDrawing = async (proposalId: string, body: TProposalSchematicDrawingBody[]) => {
    try {
      return await agent.Proposal.addSchematicDrawing(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  getSchematicDrawing = async (proposalId: string) => {
    this.schematicDrawingLoading = true;
    try {
      const res = await agent.Proposal.getSchematicDrawing(proposalId);

      runInAction(() => (this.schematicDrawing = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.schematicDrawingLoading = false));
    }
  };

  editSchematicDrawing = async (proposalId: string, body: TProposalSchematicDrawingBody[]) => {
    try {
      return await agent.Proposal.editSchematicDrawing(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  resetSchematicDrawing = () => {
    this.schematicDrawing = null;
  };

  // PREPARATION PERMISSION CRUD
  addPreparationPermission = async (proposalId: string, body: TProposalPreparationPermissionBody[]) => {
    try {
      return await agent.Proposal.addPreparationPermission(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  getPreparationPermission = async (proposalId: string) => {
    this.preparationPermissionLoading = true;
    try {
      const res = await agent.Proposal.getPreparationPermission(proposalId);

      runInAction(() => (this.preparationPermission = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.preparationPermissionLoading = false));
    }
  };

  editPreparationPermission = async (proposalId: string, body: TProposalPreparationPermissionBody[]) => {
    try {
      return await agent.Proposal.editPreparationPermission(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  resetPreparationPermission = () => {
    this.preparationPermission = null;
  };

  // PREPARATION CRUD
  addPreparation = async (proposalId: string, body: TProposalPreparationBody[]) => {
    try {
      return await agent.Proposal.addPreparation(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  getPreparation = async (proposalId: string) => {
    this.preparationLoading = true;
    try {
      const res = await agent.Proposal.getPreparation(proposalId);

      runInAction(() => (this.preparation = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.preparationLoading = false));
    }
  };

  editPreparation = async (proposalId: string, body: TProposalPreparationBody[]) => {
    try {
      return await agent.Proposal.editPreparation(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  resetPreparation = () => {
    this.preparation = null;
  };

  addProductAnalysis = async (proposalId: string, body: IProposalProductAnalysisBody[]) => {
    try {
      return await agent.Proposal.addProductAnalysis(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  editProductAnalysis = async (proposalId: string, body: IProposalProductAnalysisEditBody[]) => {
    try {
      return await agent.Proposal.editProductAnalysis(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getProductAnalysis = async (proposalId: string) => {
    this.productAnalisysLoading = true;
    try {
      const res = await agent.Proposal.getProductAnalysis(proposalId);

      runInAction(() => (this.productAnalysis = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.productAnalisysLoading = false));
    }
  };
  resetProductAnalysis = () => {
    this.productAnalysis = null;
  };

  addMarketAnalysis = async (proposalId: string, body: IProposalMarketAnalysisBody[]) => {
    try {
      return await agent.Proposal.addMarketAnalysis(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getMarketAnalysis = async (proposalId: string) => {
    this.marketAnalysisLoading = true;
    try {
      const res = await agent.Proposal.getMarketAnalysis(proposalId);

      runInAction(() => (this.marketAnalysis = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.marketAnalysisLoading = false));
    }
  };
  resetMarketAnalysis = () => {
    this.marketAnalysis = null;
  };
  editMarketingAnalysis = async (proposalId: string, body: IProposalMarketAnalysisEditBody[]) => {
    try {
      return await agent.Proposal.editMarketAnalysis(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addCostplanAnalysis = async (proposalId: string, body: IProposalCostplanAnalysisBody[]) => {
    try {
      return await agent.Proposal.addCostplanAnalysis(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getCostplanAnalysis = async (proposalId: string) => {
    this.costplanAnalysisLoading = true;
    try {
      const res = await agent.Proposal.getCostplanAnalysis(proposalId);

      runInAction(() => (this.costplanAnalysis = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.costplanAnalysisLoading = false));
    }
  };
  resetCostplanAnalysis = () => {
    this.costplanAnalysis = null;
  };
  editCostplanAnalysis = async (proposalId: string, body: IProposalCostplanAnalysisEditBody[]) => {
    try {
      return await agent.Proposal.editCostplanAnalysis(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addCollaborationAnalysis = async (proposalId: string, body: IProposalCollaborationAnalysisBody[]) => {
    try {
      return await agent.Proposal.addCollaborationAnalysis(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getCollaborationAnalysis = async (proposalId: string) => {
    this.collaborationAnalysisLoading = true;
    try {
      const res = await agent.Proposal.getCollaborationAnalysis(proposalId);

      runInAction(() => (this.collaborationAnalysis = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.collaborationAnalysisLoading = false));
    }
  };
  resetCollaborationAnalysis = () => {
    this.collaborationAnalysis = null;
  };
  editCollaborationAnalysis = async (proposalId: string, body: IProposalCollaborationAnalysisEditBody[]) => {
    try {
      return await agent.Proposal.editCollaborationAnalysis(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addEconomyAnalysis = async (proposalId: string, body: IProposalEconomyAnalysisBody[]) => {
    try {
      return await agent.Proposal.addEconomyAnalysis(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getEconomyAnalysis = async (proposalId: string) => {
    this.economyAnalysisLoading = true;
    try {
      const res = await agent.Proposal.getEconomyAnalysis(proposalId);

      runInAction(() => (this.economyAnalysis = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.economyAnalysisLoading = false));
    }
  };
  resetEconomyAnalysis = () => {
    this.economyAnalysis = null;
  };
  editEconomyAnalysis = async (proposalId: string, body: IProposalEconomyAnalysisEditBody[]) => {
    try {
      return await agent.Proposal.editEconomyAnalysis(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addfeasibilityStudy = async (proposalId: string, body: IProposalFeasibilityStudyBody[]) => {
    try {
      return await agent.Proposal.addFeasibilityStudy(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getFeasibilityStudy = async (proposalId: string) => {
    this.feasibilityStudyLoading = true;
    try {
      const res = await agent.Proposal.getFeasibilityStudy(proposalId);

      runInAction(() => (this.feasibilityStudy = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.feasibilityStudyLoading = false));
    }
  };
  resetFeasibilityStudy = () => {
    this.feasibilityStudy = null;
  };
  editFeasibilityStudy = async (proposalId: string, body: IProposalFeasibilityStudyEditBody[]) => {
    try {
      return await agent.Proposal.editFeasibilityStudy(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addFID = async (proposalId: string, body: IProposalFID[]) => {
    try {
      return await agent.Proposal.addFID(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addAttachment = async (proposalId: string, body: any) => {
    try {
      return await agent.Proposal.addAttachment(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  getAttachment = async (proposalId: string) => {
    this.attachmentLoading = true;
    try {
      const res = await agent.Proposal.getAttachment(proposalId);

      runInAction(() => (this.attachment = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.attachmentLoading = false));
    }
  };
  resetAttachment = () => {
    this.attachment = null;
  };
  editAttachment = async (proposalId: string, body: any) => {
    try {
      return await agent.Proposal.editAttachment(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addKajianFs = async (proposalId: string, body: any) => {
    try {
      return await agent.Proposal.addKajianFs(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getKajianFs = async (proposalId: string) => {
    this.kajianFsLoading = true;
    try {
      const res = await agent.Proposal.getKajianFs(proposalId);

      runInAction(() => (this.kajianFs = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.kajianFsLoading = false));
    }
  };
  resetKajianFs = () => {
    this.kajianFs = null;
  };
  editKajianFs = async (proposalId: string, body: any) => {
    try {
      return await agent.Proposal.editKajianFs(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  deleteKajianFs = async (proposalId: string) => {
    try {
      return await agent.Proposal.deleteKajianFs(proposalId);
    } catch (e) {
      throw e;
    }
  };

  addRisk = async (proposalId: string, body: IProposalRiskBody[]) => {
    try {
      return await agent.Proposal.addRisk(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getRisk = async (proposalId: string) => {
    this.riskLoading = true;
    try {
      const res = await agent.Proposal.getRisk(proposalId);

      runInAction(() => (this.risk = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.riskLoading = false));
    }
  };
  resetRisk = () => {
    this.risk = null;
  };
  editRisk = async (proposalId: string, body: IProposalRiskEditBody[]) => {
    try {
      return await agent.Proposal.editRisk(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addLegal = async (proposalId: string, body: IProposalLegalBody[]) => {
    try {
      return await agent.Proposal.addLegal(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getLegal = async (proposalId: string) => {
    this.legalLoading = true;
    try {
      const res = await agent.Proposal.getLegal(proposalId);

      runInAction(() => (this.legal = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.legalLoading = false));
    }
  };
  resetLegal = () => {
    this.legal = null;
  };
  editLegal = async (proposalId: string, body: IProposalLegalEditBody[]) => {
    try {
      return await agent.Proposal.editLegal(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addPermission = async (proposalId: string, body: IProposalPermissionBody[]) => {
    try {
      return await agent.Proposal.addPermission(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getPermission = async (proposalId: string) => {
    this.permissionLoading = true;
    try {
      const res = await agent.Proposal.getPermission(proposalId);

      runInAction(() => (this.permission = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.permissionLoading = false));
    }
  };
  resetPermission = () => {
    this.permission = null;
  };
  editPermission = async (proposalId: string, body: IProposalPermissionEditBody[]) => {
    try {
      return await agent.Proposal.editPermission(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addScopeOfWork = async (proposalId: string, body: IProposalScopeOfWorkBody[]) => {
    try {
      return await agent.Proposal.addScopeOfWork(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getScopeOfWork = async (proposalId: string) => {
    this.scopeOfWorkLoading = true;
    try {
      const res = await agent.Proposal.getScopeOfWork(proposalId);

      runInAction(() => (this.scopeOfWork = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.scopeOfWorkLoading = false));
    }
  };
  resetScopeOfWork = () => {
    this.scopeOfWork = null;
  };
  editScopeOfWork = async (proposalId: string, body: IProposalScopeOfWorkEditBody[]) => {
    try {
      return await agent.Proposal.editScopeOfWork(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addPsBackgroundObjective = async (proposalId: string, body: IProposalPsBackgroundObjectiveBody[]) => {
    try {
      return await agent.Proposal.addPsBackgroundObjective(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getPsBackgroundObjective = async (proposalId: string) => {
    this.psBackgroundObjectiveLoading = true;
    try {
      const res = await agent.Proposal.getPsBackgroundObjective(proposalId);

      runInAction(() => (this.psBackgroundObjective = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.psBackgroundObjectiveLoading = false));
    }
  };
  resetPsBackgroundObjective = () => {
    this.psBackgroundObjective = null;
  };
  editPsBackgroundObjective = async (proposalId: string, body: IProposalPsBackgroundObjectiveEditBody[]) => {
    try {
      return await agent.Proposal.editPsBackgroundObjective(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addBackgroundObjective = async (proposalId: string, body: IProposalBackgroundObjectiveBody[]) => {
    try {
      return await agent.Proposal.addBackgroundObjective(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getBackgroundObjective = async (proposalId: string) => {
    this.backgroundObjectiveLoading = true;
    try {
      const res = await agent.Proposal.getBackgroundObjective(proposalId);

      runInAction(() => (this.backgroundObjective = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.backgroundObjectiveLoading = false));
    }
  };
  resetBackgroundObjective = () => {
    this.backgroundObjective = null;
  };
  editBackgroundObjective = async (proposalId: string, body: IProposalBackgroundObjectiveEditBody[]) => {
    try {
      return await agent.Proposal.editBackgroundObjective(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addTimeline = async (proposalId: string, body: IProposalTimelineBody[]) => {
    try {
      return await agent.Proposal.addTimeline(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getTimeline = async (proposalId: string) => {
    this.timelineLoading = true;
    try {
      const res = await agent.Proposal.getTimeline<IProposalTimeline[]>(proposalId);

      runInAction(() => (this.timeline = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.timelineLoading = false));
    }
  };
  resetTimeline = () => {
    this.timeline = null;
  };
  editTimeline = async (proposalId: string, body: IProposalTimelineEditBody[]) => {
    try {
      return await agent.Proposal.editTimeline(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  getInvestmentBudget = async (proposalId: string) => {
    this.investmentBudgetLoading = true;
    try {
      const res = await agent.Proposal.getTimeline<IProposalInvestmentBudget[]>(proposalId);

      runInAction(() => (this.investmentBudget = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.investmentBudgetLoading = false));
    }
  };
  deleteInvestmentBudget = async (proposalId: string, id: string) => {
    this.deleteInvestmentBudgetLoading = true;
    try {
      return await agent.Proposal.deleteTimeline(proposalId, id);
    } finally {
      runInAction(() => (this.deleteInvestmentBudgetLoading = false));
    }
  };
  resetInvestmentBudget = () => {
    this.investmentBudget = null;
  };
  addInvestmentBudget = async (proposalId: string, body: IProposalInvestmentBudgetEditBody[]) => {
    try {
      return await agent.Proposal.addTimeline(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  editInvestmentBudget = async (proposalId: string, body: IProposalInvestmentBudgetEditBody[]) => {
    try {
      return await agent.Proposal.editTimeline(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  getCostBenefit = async (proposalId: string) => {
    this.costBenefitLoading = true;
    try {
      const res = await agent.Proposal.getTimeline<IProposalCostBenefit[]>(proposalId);

      runInAction(() => (this.costBenefit = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.costBenefitLoading = false));
    }
  };
  resetCostBenefit = () => {
    this.costBenefit = null;
  };
  editCostBenefit = async (proposalId: string, body: IProposalCostBenefitEditBody[]) => {
    try {
      return await agent.Proposal.editTimeline(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addDrawdown = async (proposalId: string, body: IProposalDrawdownBody[]) => {
    try {
      return await agent.Proposal.addDrawdown(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getDrawdown = async (proposalId: string) => {
    this.drawdownLoading = true;
    try {
      const res = await agent.Proposal.getDrawdown(proposalId);

      runInAction(() => (this.drawdown = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.drawdownLoading = false));
    }
  };
  resetDrawdown = () => {
    this.drawdown = null;
  };
  editDrawdown = async (proposalId: string, body: IProposalDrawdownEditBody[]) => {
    try {
      return await agent.Proposal.editDrawdown(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addConstructor = async (proposalId: string, body: IProposalConstructorBody[]) => {
    try {
      return await agent.Proposal.addConstructor(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getConstructor = async (proposalId: string) => {
    this.constructorsLoading = true;
    try {
      const res = await agent.Proposal.getConstructor(proposalId);

      runInAction(() => (this.constructors = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.constructorsLoading = false));
    }
  };
  resetConstructor = () => {
    this.constructors = null;
  };
  editConstructor = async (proposalId: string, body: IProposalConstructorEditBody[]) => {
    try {
      return await agent.Proposal.editConstructor(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addPsRiskMitigation = async (proposalId: string, body: IProposalPsRiskMitigationBody[]) => {
    try {
      return await agent.Proposal.addPsRiskMitigation(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getPsRiskMitigation = async (proposalId: string) => {
    this.psRiskMitigationLoading = true;
    try {
      const res = await agent.Proposal.getPsRiskMitigation(proposalId);

      runInAction(() => (this.psRiskMitigation = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.psRiskMitigationLoading = false));
    }
  };
  resetPsRiskMitigation = () => {
    this.psRiskMitigation = null;
  };
  editPsRiskMitigation = async (proposalId: string, body: IProposalPsRiskMitigationEditBody[]) => {
    try {
      return await agent.Proposal.editPsRiskMitigation(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  addtechnicalAnalysis = async (proposalId: string, body: IProposalTechnicalAnalysisBody[]) => {
    try {
      return await agent.Proposal.addtechnicalAnalysis(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  getTechnicalAnalysis = async (proposalId: string) => {
    this.technicalAnalysisLoading = true;
    try {
      const res = await agent.Proposal.getTechnicalAnalysis(proposalId);

      runInAction(() => (this.technicalAnalysis = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.technicalAnalysisLoading = false));
    }
  };
  resetTechnicalAnalysis = () => {
    this.technicalAnalysis = null;
  };
  editTechnicalAnalysis = async (proposalId: string, body: IProposalTechnicalAnalysisEditBody[]) => {
    try {
      return await agent.Proposal.editTechnicalAnalysis(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  getWbs = async (proposalId: string, year: string) => {
    this.wbsLoading = true;
    try {
      const res = await agent.Proposal.getWBS(proposalId, year);

      // const newRes = res.map((item) => {
      //   return {
      //     data: item.data.map((data) => {
      //       return {
      //         x: data.x,
      //         y: data.y.map((date) => new Date(date).getTime()) as [number, number],
      //       };
      //     }),
      //   };
      // });

      runInAction(() => (this.wbs = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.wbsLoading = false));
    }
  };

  deleteAttachment = async (proposalId: string, id: string) => {
    this.deleteAttachmentLoading = true;
    try {
      await agent.Proposal.deleteAttachment(proposalId, id);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.deleteAttachmentLoading = false));
    }
  };

  getTask = async (proposalId: string) => {
    this.taskLoading = true;
    try {
      const res = await agent.Proposal.getTasks(proposalId);
      runInAction(() => (this.task = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.taskLoading = false));
    }
  };
}
