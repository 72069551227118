import { makeAutoObservable, reaction, runInAction } from "mobx";
import { Project } from "../models/project";
import agent from "../api/agent";
import { DataGridResult } from "../models/materialUI/dataGrid";
import { ProjectStatus } from "../config/enum";
import { IProposalLog, TProposalRefidHistory } from "../models/proposalLog";

type QueryParams = {
  pageSize: number;
  currentPage: number;
  search: string;
  projectType: string;
};

const initialQueryParams: QueryParams = {
  currentPage: 0,
  pageSize: 25,
  search: "",
  projectType: "",
};

export class ProjectManagementStore {
  project: DataGridResult<Project[]> = new DataGridResult([], 0);
  projectLoading: boolean = false;

  projectStatus: ProjectStatus | null = null;

  reviewerApproveRejectLoading = false;
  decisionGateApproveRejectLoading = false;

  log: IProposalLog[] = [];
  logLoading = false;

  refidLogs: TProposalRefidHistory[] = [];
  refidLogsLoading = false;

  queryparams: QueryParams = initialQueryParams;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.queryparams,
      () => {
        this.getProject();
      }
    );
  }

  setQueryParams = (query: "pageSize" | "search" | "currentPage" | "projectCategoryId" | "projectType", v: string | number) => {
    this.queryparams = {
      ...this.queryparams,
      [query]: v,
    };
  };

  get currentProjectStatus() {
    switch (this.projectStatus) {
      case ProjectStatus.Proposal:
        return {
          status: ProjectStatus.Proposal,
          text: "Proposal",
          step: 1,
        };
      case ProjectStatus.Reviewer:
        return {
          status: ProjectStatus.Reviewer,
          text: "Reviewer",
          step: 2,
        };
      case ProjectStatus.DecisionGate:
        return {
          status: ProjectStatus.DecisionGate,
          text: "Decision Gate",
          step: 3,
        };
      case ProjectStatus.Fid:
        return {
          status: ProjectStatus.Fid,
          text: "FID",
          step: 4,
        };
      case ProjectStatus.Preparation:
        return {
          status: ProjectStatus.Preparation,
          text: "Preparation",
          step: 5,
        };
      case ProjectStatus.Procurement:
        return {
          status: ProjectStatus.Procurement,
          text: "Procurement",
          step: 6,
        };
      case ProjectStatus.Construction:
        return {
          status: ProjectStatus.Construction,
          text: "Construction",
          step: 7,
        };
      case ProjectStatus.ProjectClosing:
        return {
          status: ProjectStatus.ProjectClosing,
          text: "Project Closing",
          step: 8,
        };
    }

    return null;
  }

  get nextProjectStatus() {
    switch (this.projectStatus) {
      case ProjectStatus.Proposal:
        return {
          status: ProjectStatus.Reviewer,
          text: "Reviewer",
          step: 2,
        };
      case ProjectStatus.Reviewer:
        return {
          status: ProjectStatus.DecisionGate,
          text: "Decision Gate",
          step: 3,
        };
      case ProjectStatus.DecisionGate:
        return {
          status: ProjectStatus.Fid,
          text: "FID",
          step: 4,
        };
      case ProjectStatus.Fid:
        return {
          status: ProjectStatus.Preparation,
          text: "Preparation",
          step: 5,
        };
      case ProjectStatus.Preparation:
        return {
          status: ProjectStatus.Procurement,
          text: "Procurement",
          step: 6,
        };
      case ProjectStatus.Procurement:
        return {
          status: ProjectStatus.Construction,
          text: "Construction",
          step: 7,
        };
      case ProjectStatus.Construction:
        return {
          status: ProjectStatus.ProjectClosing,
          text: "Project Closing",
          step: 7,
        };
    }

    return null;
  }

  getProject = async (projectCategoryId?: string, projectType?: string, search?: string) => {
    this.projectLoading = true;
    try {
      const result = await agent.ProjectManagement.projectGrid({ ...this.queryparams, search, projectCategoryId, projectType });

      runInAction(() => (this.project = result));
    } catch (e) {
      throw e;
    } finally {
      this.projectLoading = false;
    }
  };

  setProjectStatus = (status: ProjectStatus | null) => {
    this.projectStatus = status;
  };

  cancelProject = async (proposalId: string, body: any) => {
    try {
      await agent.Proposal.cancelProject(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  promoteProposalToDecisionGate = async (proposalId: string, body: any) => {
    try {
      await agent.Proposal.promoteProposalToDecisionGate(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  promoteProposal = async (proposalId: string, status: ProjectStatus) => {
    try {
      await agent.Proposal.promoteProposal(proposalId, status);
    } catch (e) {
      throw e;
    }
  };

  reviewerApproveRefuseProposal = async (proposalId: string, body: any) => {
    this.reviewerApproveRejectLoading = true;
    try {
      await agent.ProjectManagement.reviewerApproveRefuse(proposalId, body);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.reviewerApproveRejectLoading = false));
    }
  };

  decisionGateApproveRefuseProposal = async (proposalId: string, body: any) => {
    this.decisionGateApproveRejectLoading = true;
    try {
      await agent.ProjectManagement.decisionGateApproveRefuse(proposalId, body);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.decisionGateApproveRejectLoading = true));
    }
  };

  getLog = async (proposalId: string) => {
    this.logLoading = true;
    try {
      const res = await agent.Proposal.getLog(proposalId);

      runInAction(() => (this.log = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.logLoading = false));
    }
  };

  getRefidLogs = async (proposalId: string) => {
    this.refidLogsLoading = true;
    try {
      const res = await agent.Proposal.getRefidLog(proposalId);

      runInAction(() => (this.refidLogs = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.refidLogsLoading = false));
    }
  };
}
